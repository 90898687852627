import React, { createContext, useState, useContext } from 'react';

const DialogContext = createContext();

export const DialogProvider = ({ children }) => {
  const [isCustomizeWidgetOpen, setIsCustomizeWidgetOpen] = useState(false);

  const openCustomizeWidget = () => setIsCustomizeWidgetOpen(true);
  const closeCustomizeWidget = () => setIsCustomizeWidgetOpen(false);

  return (
    <DialogContext.Provider value={{ isCustomizeWidgetOpen, openCustomizeWidget, closeCustomizeWidget }}>
      {children}
    </DialogContext.Provider>
  );
};

export const useDialogContext = () => useContext(DialogContext);
