import './App.css';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Login from './views/auth/Login';
import ForgotPassword from './views/auth/ForgotPassword';
import ResetPassword from './views/auth/ResetPassword';
import HomeLayout from './layouts/HomeLayout';
import Home from "./views/Home";
import Portfolio from './views/Portfolio';
import AI from './views/AI';
import Loader from './views/components/Loader';
import { AuthProvider } from './views/context/AuthContext';
import ProtectedRoute from './views/ProtectedRoute';
import { WidgetProvider } from './views/context/DragContext'
import { DialogProvider } from './views/context/DialogContext';

function LoaderWrapper({ children }) {
  const [loading, setLoading] = useState(false);
  const location = useLocation(); // Hook to get the current location

  // Use useEffect to track location changes and trigger the loader
  useEffect(() => {
    setLoading(true); // Show the loader when the route changes

    // Simulate loading for 1 second (or until the new component is rendered)
    const timer = setTimeout(() => {
      setLoading(false); // Hide the loader after loading is complete
    }, 100);

    return () => clearTimeout(timer); // Cleanup the timer
  }, [location]); // Trigger useEffect on location changes

  return (
    <>
      {loading && <Loader />} {/* Display the loader when loading is true */}
      {children}
    </>
  );
}

function App() {
  return (
      <div className='App'>
          <div className="background">
              <div className="circle circle-yellow"></div>
              <div className="circle circle-purple"></div>
              <div className="circle circle-orange"></div>
              <div className="circle circle-blue"></div>
              <div className="overlay"></div>
          </div>
          <BrowserRouter>
              <AuthProvider>
                <DialogProvider>
                    <LoaderWrapper>
                        <Routes>
                            <Route path='/' element={<Login />} />
                            <Route path="/forgot-password" element={<ForgotPassword />} />
                            <Route path="/change-password" element={<ResetPassword />} />
                            <Route path='/Home' element={<ProtectedRoute><WidgetProvider><HomeLayout /></WidgetProvider></ProtectedRoute>}>
                                <Route path='Landing' element={<Home />} />
                                <Route path='Portfolio' element={<Portfolio />} />
                                <Route path='AI' element={<AI />} />
                            </Route>
                        </Routes>
                    </LoaderWrapper>
                  </DialogProvider>
              </AuthProvider>
          </BrowserRouter>
      </div>
  );
}

export default App;
