import React from "react";
import { Card } from "react-bootstrap";

const Policy = ({ handleClose }) => {
    return (
        <Card className="policy-card">
            <Card.Body style={{ paddingBottom: '25px' }}>
                <img src={`${process.env.PUBLIC_URL}/images/close.png`} alt="close" className="close-icon" role="button" onClick={handleClose} />
                <div className="text-center mb-4 mt-4">
                    <img src={`${process.env.PUBLIC_URL}/images/logo.webp`} className="App-logo" alt="logo" />
                </div>
                <div className="text-white policy-div scrollbar-custom">
                    <p>
                        Effective Date: July 1st, 2024
                    </p>
                    <b>Introduction</b>
                    <p>Welcome to No Doji Commodity. Your privacy is of utmost importance to us. This
                        Privacy Policy outlines how we collect, use, disclose, and safeguard your information
                        when you visit our website, NoDojiCommodity.com, or use our trading platform. By
                        using our services, you agree to the terms of this Privacy Policy.</p>

                    <b>Information We Collect</b>
                    <p>We may collect the following types of information:
                        <ol>
                            <li><b>Personal Information:</b>
                                <ul>
                                    <li>Name</li>
                                    <li>Email address</li>
                                    <li>Phone number</li>
                                    <li>Mailing address</li>
                                    <li>Payment information</li>
                                </ul>
                            </li>
                            <li><b>Usage Data:</b>
                                <ul>
                                    <li>IP address</li>
                                    <li>Browser type and version</li>
                                    <li>Pages you visit on our website</li>
                                    <li>Time and date of your visit</li>
                                    <li>Time spent on those pages</li>
                                    <li>Other diagnostic data</li>
                                </ul>
                            </li>
                            <li><b>Cookies and Tracking Technologies:</b>
                                <ul>
                                    <li>We use cookies and similar tracking technologies to track activity on our
                                        service and hold certain information. You can instruct your browser to
                                        refuse all cookies or to indicate when a cookie is being sent. However, if
                                        you do not accept cookies, you may not be able to use some portions of
                                        our service.</li>
                                </ul>
                            </li>
                        </ol>
                    </p>
                    <b>How We Use Your Information</b>
                    <p>We use the information we collect in the following ways:
                        <ol>
                            <li><b>To Provide and Maintain Our Service:</b>
                                <ul>
                                    <li>To process transactions and send you related information, including
                                        purchase confirmations and invoices.</li>
                                    <li>To provide customer support and respond to your inquiries.</li>
                                </ul>
                            </li>
                            <li><b>To Improve Our Service:</b>
                                <ul>
                                    <li>To understand and analyze how you use our service and develop new
                                        products, services, features, and functionality.</li>
                                    <li>To monitor and analyze usage and trends to improve your experience with
                                        our service.</li>
                                </ul>
                            </li>
                            <li><b>To Communicate With You:</b>
                                <ul>
                                    <li>To send you technical notices, updates, security alerts, and support and
                                        administrative messages.</li>
                                    <li>To send promotional communications that may be of interest to you,
                                        including information about products, services, offers, promotions, news,
                                        and events.</li>
                                </ul>
                            </li>
                            <li><b>To Enforce Our Policies and Terms:</b>
                                <ul>
                                    <li>To comply with legal obligations and resolve any disputes we may have
                                        with any of our users.</li>
                                </ul>
                            </li>
                        </ol>
                    </p>
                    <b>How We Share Your Information</b>
                    <p>We do not sell, trade, or otherwise transfer your personal information to outside parties
                        except as described in this Privacy Policy. We may share information with third parties
                        who perform services on our behalf, such as payment processing, data analysis, email
                        delivery, hosting services, customer service, and marketing assistance.</p>
                    <p>
                        We may also disclose your information:
                        <ul>
                            <li>To comply with legal obligations</li>
                            <li>To protect and defend our rights or property</li>
                            <li>To prevent or investigate possible wrongdoing in connection with our service</li>
                            <li>To protect the personal safety of users of our service or the public</li>
                            <li>To protect against legal liability</li>
                        </ul>
                    </p>
                    <b>Data Security</b>
                    <p>We use administrative, technical, and physical security measures to help protect your
                        personal information. While we have taken reasonable steps to secure the personal
                        information you provide to us, please be aware that despite our efforts, no security
                        measures are perfect or impenetrable, and no method of data transmission can be
                        guaranteed against any interception or other type of misuse.</p>
                    <b>Your Data Protection Rights</b>
                    <p>Depending on your location, you may have the following rights regarding your personal
                        data:
                        <ul>
                            <li>The right to access - You have the right to request copies of your personal data.</li>
                        </ul>
                        The right to rectification - You have the right to request that we correct any information
                        you believe is inaccurate or complete information you believe is incomplete.
                        <ul>
                            <li>The right to erasure - You have the right to request that we erase your personal
                                data, under certain conditions.</li>
                            <li>The right to restrict processing - You have the right to request that we restrict the
                                processing of your personal data, under certain conditions.</li>
                            <li>The right to object to processing -</li>
                        </ul>
                        You have the right to object to our processing of your personal data, under certain
                        conditions.
                        <ul>
                            <li>The right to data portability - You have the right to request that we transfer the
                                data we have collected to another organization, or directly to you, under certain
                                conditions.</li>
                        </ul>
                    </p>
                    <p>To exercise these rights, please contact us at: <a href="mailto: Support@NoDojiCommodity.com">Support@NoDojiCommodity.com</a></p>
                    <b>Third-Party Links</b>
                    <p>Our service may contain links to third-party websites that are not operated by us. If you
                        click on a third-party link, you will be directed to that third party's site.</p>
                    <p>We strongly advise you to review the privacy policy of any third-party site you visit. We
                        have no control over and assume no responsibility for the content, privacy policies, or
                        practices of any third-party websites or services.</p>
                    <b>California Residents</b>
                    <p>Users of the Site who are California residents and who have provided PII to us may
                        request certain information regarding our disclosure of personal information to third
                        parties for their direct marketing purposes. To make such a request, please send an e-
                        mail to <a href="mailto: Support@NoDojiCommodity.com">Support@NoDojiCommodity.com</a> with "Request for California Privacy
                        Information" in the subject line.</p>
                    <b>Questions</b>
                    <p>If you have additional questions, we can be reached at:</p>
                    <p>
                        <a href="mailto: Support@NoDojiCommodity.com">Support@NoDojiCommodity.com</a><br></br>
                        No Doji Commodity LLC <br />
                        5742 SW Cedar CT<br />
                        Topeka, KS 66610
                    </p>
                </div>
            </Card.Body>
        </Card>
    );
};

export default Policy;
