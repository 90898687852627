import React, { createContext, useState, useContext } from 'react';

// Create the context
const WidgetContext = createContext();

// Custom hook for accessing the context
export const useWidgetContext = () => {
    return useContext(WidgetContext);
};

// Provider component
export const WidgetProvider = ({ children }) => {
    const [isHighlighted, setIsHighlighted] = useState(false)
    const [addedWidgetKey, setAddedWidgetKey] = useState('')
    // Initialize the grid with some pre-populated widgets
    const [grid, setGrid] = useState([
        ['watchlist', 'chart', null, 'info'],  
        ['news', null, null, 'trade'],
        ['transaction', null, null, 'bidask']
    ]);

    const widgets = {
        chart: { src: 'commodity_chart.svg', label: 'Chart', height: 3, width: 2 },
        info: { src: 'commodity_info.svg', label: 'Commodity Info', height: 1, width: 1 },
        bidask: { src: 'commodity_bid_ask.svg', label: 'Bid & Ask', height: 1, width: 1 },
        supplydemand: { src: 'commodity_supply_demand.svg', label: 'Supply & Demand', height: 1, width: 1 },
        trends: { src: 'market_index_constituents.svg', label: 'Market Trends', height: 1, width: 1 },
        analysis: { src: 'market_data.svg', label: 'Market Analysis', height: 1, width: 1 },
        predictions: { src: 'market_monitor.svg', label: 'Market Predictions', height: 1, width: 1 },
        gl: { src: 'market_l.svg', label: 'Market G/L', height: 1, width: 1 },
        heatmap: { src: 'market_heat_map.svg', label: 'Heat Map', height: 1, width: 1 },
        index: { src: 'market_index.svg', label: 'Market Index', height: 1, width: 1 },
        topsectors: { src: 'market_top_sectors.svg', label: 'Top Sectors', height: 1, width: 1 },
        sectorlist: { src: 'market_sector_list.svg', label: 'Sector List', height: 1, width: 1 },
        watchlist: { src: 'general_watchlist.svg', label: 'Watchlist', height: 1, width: 1 },
        screener: { src: 'general_screener.svg', label: 'Screener', height: 1, width: 1 },
        news: { src: 'general_news.svg', label: 'News', height: 1, width: 1 },
        trade: { src: 'trade.svg', label: 'Trade', height: 1, width: 1 },
        holdings: { src: 'trade_holdings.svg', label: 'Holdings', height: 1, width: 2 },
        order: { src: 'trade_history_order.svg', label: 'History Order', height: 1, width: 1 },
        transaction: { src: 'traderecent_transaction.svg', label: 'Recent Transactions', height: 1, width: 1 },
        accountoverview: { src: 'portfolio_account_overview.svg', label: 'Account Overview', height: 1, width: 1 },
        accountinformation: { src: 'portfolio_account_information.svg', label: 'Account Information', height: 2, width: 1 },
        performance: { src: 'portfolio_performance.svg', label: 'Performance', height: 2, width: 2 },
    };

    // Add a widget to the grid, ensuring it fits and doesn't overlap with others
    const addWidgetToGrid = (widgetKey, widgetSize = { width: 1, height: 1 }) => {
        const newGrid = [...grid];
        let added = false;
        
        if (isWidgetAlreadyPlaced(newGrid, widgetKey)) {
            alert("This widget is already placed.");
            return;  // Exit the function if the widget is already placed
        }
        // Get widget size from the widgets object if available, otherwise use the passed size
        const { width, height } = widgets[widgetKey] || widgetSize;
    
        // Iterate through the grid to find a space that matches the widget's size
        for (let rowIndex = 0; rowIndex < newGrid.length; rowIndex++) {
            for (let colIndex = 0; colIndex < newGrid[rowIndex].length; colIndex++) {
                // Only try placing the widget if the space is valid (check for existing widgets and size)
                if (isValidSpace(newGrid, rowIndex, colIndex, { width, height })) {
                    // Place the widget in the found space
                    for (let i = 0; i < height; i++) {
                        for (let j = 0; j < width; j++) {
                            newGrid[rowIndex + i][colIndex + j] = widgetKey;
                        }
                    }
                    added = true;
                    break;  // Exit the loop once widget is placed
                }
            }
            if (added) break;  // Exit outer loop if widget is placed
        }
    
        if (added) {
            setGrid(newGrid);  // Update the grid with the newly added widget
        } else {
            alert('No available space to add the widget.');
        }
    };

    const testGird = (widgetKey, rowIndex, colIndex, widgetSize = { width: 1, height: 1 }) => {
        const newGrid = [...grid];
        let added = false;
        
        if (isWidgetAlreadyPlaced(newGrid, widgetKey)) {
            alert("This widget is already placed.");
            return false;  // Exit the function if the widget is already placed
        }
        // Get widget size from the widgets object if available, otherwise use the passed size
        const { width, height } = widgets[widgetKey] || widgetSize;

        if (isValidSpace(newGrid, rowIndex, colIndex, { width, height })) {
            // newGrid[rowIndex][colIndex] = widgetKey;
            for (let i = 0; i < height; i++) {
                for (let j = 0; j < width; j++) {
                    if (i === 0 && j === 0) {
                        newGrid[rowIndex + i][colIndex + j] = widgetKey; // Place widget key
                    } else {
                        newGrid[rowIndex + i][colIndex + j] = null; // Reserve space with null
                    }
                }
            }
            added = true;
        }
        console.log(added)
        if (added) {
            console.log('inside if')
            setGrid(newGrid);  // Update the grid with the newly added widget
            return true;
        } else {
            console.log('else')
            alert('No available space to add the widget.');
            return false;
        }
    }
    
    const isWidgetAlreadyPlaced = (grid, widgetKey) => {
        for (let rowIndex = 0; rowIndex < grid.length; rowIndex++) {
            for (let colIndex = 0; colIndex < grid[rowIndex].length; colIndex++) {
                if (grid[rowIndex][colIndex] === widgetKey) {
                    return true;  // Widget is already placed
                }
            }
        }
        return false;  // Widget is not found
    };

    // Helper function to check if the widget fits in the available space without overlapping
    const isValidSpace = (grid, startRow, startCol, widgetSize) => {
        console.log(startRow)
        console.log(grid)
        const { width, height } = widgetSize;
    
        // Ensure widget fits within grid boundaries
        if (startRow + height > grid.length || startCol + width > grid[0].length) {
            return false;  // Widget would overflow out of bounds
        }
    
        // Check if the space is occupied by any existing widgets (based on height and width)
        for (let i = 0; i < height; i++) {
            for (let j = 0; j < width; j++) {
                if (grid[startRow + i][startCol + j] !== 0) {
                    return false;  // Space is already occupied by another widget (non-zero)
                }
            }
        }
    
        return true;  // The space is large enough and empty
    };
    
    // Remove widget by key from the grid
    const removeWidgetFromGrid = (widgetKey) => {
        const { width, height } = widgets[widgetKey];
        let startRow = -1;
        let startCol = -1;
    
        // Find the starting position of the widget
        for (let rowIndex = 0; rowIndex < grid.length; rowIndex++) {
            for (let colIndex = 0; colIndex < grid[rowIndex].length; colIndex++) {
                if (grid[rowIndex][colIndex] === widgetKey) {
                    startRow = rowIndex;
                    startCol = colIndex;
                    break; // Exit loop once found
                }
            }
            if (startRow !== -1) break; // Exit outer loop if starting position is found
        }
    
        // If starting position is found, proceed with removal
        if (startRow !== -1 && startCol !== -1) {
            const newGrid = [...grid];
            for (let i = 0; i < height; i++) {
                for (let j = 0; j < width; j++) {
                    newGrid[startRow + i][startCol + j] = 0; // Set cells to 0
                }
            }
            setGrid(newGrid);
        }
    };
    
    

    return (
        <WidgetContext.Provider value={{ grid, addWidgetToGrid, removeWidgetFromGrid , widgets, isHighlighted, setIsHighlighted, addedWidgetKey, setAddedWidgetKey, setGrid, isWidgetAlreadyPlaced, testGird}}>
            {children}
        </WidgetContext.Provider>
    );
};
