import React, { useState, useEffect, useContext } from 'react';
import { Navbar, Nav, Container, NavDropdown, Modal, Form } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import Switch from '../components/Switch';
// import { FaCog } from 'react-icons/fa';
import AccountSettingsDialog from './AccountSettingsDialog';
import CustomizeWidget from '../components/CustomizeWidget';
import axios from 'axios';
import { useDialogContext } from '../context/DialogContext';
import { AuthContext } from '../context/AuthContext';
import { showSweetAlert } from '../components/SweetAlert';

function Header() {
  const [isOn, setIsOn] = useState(true);
  const [showSupport, setShowSupport] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [openCustomWidget, setCustomWidget] = useState(false);
  const [issueType, setIssueType] = useState('');
  const [description, setDescription] = useState('');
  const { aiSettings, userData, settingParams, supportEmail } = useContext(AuthContext)
  const [name, setName] = useState("");

  // State for API data, loading, and error
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({ issueType: '', description: '' });

  const handleShowSupport = () => setShowSupport(true);
  const handleCloseSupport = () => setShowSupport(false);
  const handleOpenSettings = () => setOpenSettings(true);
  const handleCloseSettings = () => setOpenSettings(false);
  // const handleShowCustomWidget = () => setCustomWidget(true);
  // const handleCloseCustomWidget = () => setCustomWidget(false);

  const { openCustomizeWidget, closeCustomizeWidget, isCustomizeWidgetOpen } = useDialogContext();
  // Toggle the CustomizeWidget dialog using context methods

  const handleShowCustomWidget = () => {
    openCustomizeWidget(); // This should trigger the dialog to open
  };

  const handleCloseCustomWidget = () => {
    closeCustomizeWidget(); // This should trigger the dialog to close
  };

  const navigate = useNavigate();

  const handleToggle = async () => {
    setIsOn(!isOn);
    const updatedSettings = {
      ...(Object.keys(settingParams).length > 0
        ? settingParams
        : userData?.ai_settings || {}), // Use existing or fallback to userData.ai_settings
      aiOn: !isOn, // Dynamically update aiOn
    };
  
    try {
      const response = await aiSettings(updatedSettings);
      // alert('Order submitted successfully!');
    } catch (error) {
      console.error('Error submitting order:', error);
      alert('Failed to submit order.');
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate('/');
  };

  const handleSubmit =async (event) => {
    event.preventDefault();

    let valid = true;
    let newErrors = { ...errors };

    if (!issueType) {
      console.log('no email');
      newErrors.issueType = 'Select any one issue';
      valid = false;
    } else if (!description) {
        console.log('no email');
        newErrors.description = 'Description is required.';
        valid = false;
    }else {
        newErrors.issueType = '';
        newErrors.description = '';
    }
    setErrors(newErrors);
    setLoading(true);

    console.log('Submitting issue:', {
      issueType,
      description,
    });

    if (valid) {
      const response = supportEmail(issueType, description);
      if(response == "Email sent successfully."){
          setLoading(false);
          handleCloseSupport(); 
          showSweetAlert('Success!', `Email send Successfully.`, 'success');
      }
      else{
        showSweetAlert('Warning!', `Email address is not verified.`, 'warning');
      }
      // setIssueType('');
      // setDescription('');
      
     }

  };

  useEffect(() => {
    const isAiOn = userData?.ai_settings?.aiOn || false;
    setIsOn(isAiOn);
    if (userData) {
      setName(userData.name || name);
      }
  },[userData]);

  return (
    <>
      <Navbar expand="lg">
        <Container fluid>
          <Navbar.Brand as={NavLink} to="/Home/Landing"><img src={`${process.env.PUBLIC_URL}/images/logo.webp`} className="header-logo" alt="logo" /></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={NavLink} to="/Home/Landing" className={({ isActive }) => isActive ? "nav-link active" : "nav-link"}>
                Home
              </Nav.Link>
              <Nav.Link>
                Screen
              </Nav.Link>
              <Nav.Link>Analytics</Nav.Link>
              <Nav.Link as={NavLink} to="/Home/AI">AI</Nav.Link>
              <Nav.Link as={NavLink} to="/Home/Portfolio">Portfolio</Nav.Link>
              {/* <Nav.Link>Tab</Nav.Link> */}
              <Nav.Link>Untitled</Nav.Link>
              <Nav.Link>+</Nav.Link>
            </Nav>
            <Nav className="ml-auto right-nav">
              <Nav.Link style={{ paddingLeft: "0px" }}>AI Operation <img src={`${process.env.PUBLIC_URL}/images/info.svg`} alt="info-icon" /></Nav.Link>
              <Nav.Link href="#toggle" className="mb-0 toggle-switch">
                <Switch isOn={isOn} handleToggle={handleToggle} />
              </Nav.Link>
              <Nav.Link><img src={`${process.env.PUBLIC_URL}/images/bar.svg`} alt="bar-icon" onClick={handleShowCustomWidget} /></Nav.Link>
              <Nav.Link><img src={`${process.env.PUBLIC_URL}/images/message.svg`} alt="message-icon" onClick={handleShowSupport} /></Nav.Link>
              <Nav.Link><img src={`${process.env.PUBLIC_URL}/images/setting.svg`} alt="setting-icon" onClick={handleOpenSettings} /></Nav.Link>
              <NavDropdown
                title={<img src={`${process.env.PUBLIC_URL}/images/user-circle.svg`} alt="user-img" />}
                id="settings-nav-dropdown"
                align="end" // Ensure dropdown aligns to the end
                menuVariant="dark"
              >
                <NavDropdown.Item href="#profile">My Profile</NavDropdown.Item>
                <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Modal show={showSupport} onHide={handleCloseSupport} centered >
        <Modal.Body className="support-dialog">
          <img src={`${process.env.PUBLIC_URL}/images/close.png`} alt="close" className="close-icon" role="button" onClick={handleCloseSupport} />
          <h6 className="large-text">Hello {name}! <br />How can we help you today?</h6>
          <Form onSubmit={handleSubmit} className="basic-form">
            <Form.Group>
              <Form.Control
                as="select"
                className="form-input custom-select mt-4" // Apply custom CSS class
                value={issueType}
                onChange={(e) => {
                  setIssueType(e.target.value);
                  if (e.target.value) {
                    setErrors((prevErrors) => ({ ...prevErrors, issueType: '' })); // Remove error
                  }
                }}
              >
                <option value="">Select your type of issue</option>
                <option value="General Issues">General Issues</option>
                <option value="Technical Issues">Technical Issues</option>
                <option value="Misc. Support Issues">Misc. Support Issues</option>
              </Form.Control>
            </Form.Group>
            {errors.issueType && <div className="text-danger mt-1">{errors.issueType}</div>}

            <Form.Group>
              <Form.Control
                as="textarea"
                className="form-input mt-4 support-desc"
                placeholder="Description"
                value={description}
                rows={4}
                onChange={(e) => {
                  setDescription(e.target.value);
                  if (e.target.value.trim()) {
                    setErrors((prevErrors) => ({ ...prevErrors, description: '' })); // Remove error
                  }
                }}
              // style={{height: '186px'}}
              />
            </Form.Group>
            {errors.description && <div className="text-danger mt-1">{errors.description}</div>}

            <div className="row justify-content-center submit-btn">
              <button type="submit" className="w-55 p-2 theme-btn btn-primary" style={{ color: "#FFF", border: "unset" }}>Submit</button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {/* <Modal show={showSupport} onHide={handleCloseSupport} centered>
        <Modal.Body>
          <img src={`${process.env.PUBLIC_URL}/images/close.png`} alt="close" className="close-icon" role="button" onClick={handleCloseSupport} />
          
          <div className="row">
            <div className="col-sm-3" style={{borderRight: "1px solid #48494C", borderRadius: "4px"}}>
              <h6 className="large-text">Widgets</h6>

              <input type="text" className="form-control" placeholder="search widget" />
            </div>
            <div className="col-sm-9">
            
            </div>
          </div>
          
        </Modal.Body>
      </Modal> */}

      <AccountSettingsDialog open={openSettings} onClose={handleCloseSettings} />
      {/* <CustomizeWidget open={openCustomWidget} onClose={handleCloseCustomWidget} /> */}
      <CustomizeWidget open={isCustomizeWidgetOpen} onClose={handleCloseCustomWidget} />
    </>
  );
}

export default Header;
