import React, { useState, useContext, useEffect } from 'react';
import { Card, Table, NavDropdown } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useWidgetContext } from '../context/DragContext';
import { AuthContext } from '../context/AuthContext';

// CustomDropdown Component
const CustomDropdown = ({ options, selectedOption, onSelect }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => setIsOpen(!isOpen);

    const handleSelect = (option) => {
        const valueToPass = option.replace('Sort by ', '');
        onSelect(valueToPass); // Pass the value without "Sort by "
        setIsOpen(false);
    };

    return (
        <div className="custom-dropdown">
            <button className="select-btn" onClick={handleToggle}>
            {`Sort by ${selectedOption}`} <img src={`${process.env.PUBLIC_URL}/images/down 2.svg`} alt="icon" className="float-right mt-1" />
            </button>
            {isOpen && (
                <div className="select-options">
                    {options.map((option) => (
                        <div
                            key={option}
                            className="option"
                            onClick={() => handleSelect(option)}
                        >
                            {option}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

const formatTradeTime = (tradeTime) => {
    // Extract year, month, day from the trade_time string (e.g., "241115074150")
    const year = `20${tradeTime.substring(0, 2)}`; // "20" + "24" = "2024"
    const month = tradeTime.substring(2, 4); // "11"
    const day = tradeTime.substring(4, 6); // "15"
    
    // Create a new Date object using the extracted year, month (adjusted for zero-based index), and day
    const formattedDate = new Date(`${year}-${month}-${day}`);
    
    // Format the date to MM/DD/YY
    const options = { year: '2-digit', month: '2-digit', day: '2-digit' };
    return formattedDate.toLocaleDateString('en-US', options); // "MM/DD/YY" format
};





function RecentTransaction() {
    const [sortOption, setSortOption] = useState('Ticker');
    const [sortedTransactions, setSortedTransactions] = useState([]);
    const [sortedOrders, setSortedOrders] = useState([]);
    const [sortedTrades, setSortedTrades] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const { removeWidgetFromGrid } = useWidgetContext();
    const { recentTransactionsData, tradeData } = useContext(AuthContext);

    // console.log(recentTransactionsData);

    const handleToggle = (isOpen) => {
        setShowDropdown(isOpen);
    };

    const handleSvgClick = () => {
        setShowDropdown(!showDropdown);
    };

    // Combine and mark data for sorting
    const combineData = () => {
        const orders = (recentTransactionsData || []).map((item) => ({
            ...item,
            dataType: 'order', // Mark as order
            orderQty: parseFloat(item.totalSize || 0), // Placeholder for sorting by size
        }));

        const trades = (tradeData || []).map((item) => ({
            ...item,
            dataType: 'trade', // Mark as trade
            orderQty: parseFloat(item.size || 0), // Placeholder for sorting by size
        }));

        return [...orders, ...trades];
    };

    // useEffect(() => {
    //     if (recentTransactionsData) {
    //         setSortedOrders(recentTransactionsData);
    //     }
    //     if (tradeData) {
    //         setSortedTrades(tradeData);
    //     }
    // }, [recentTransactionsData, tradeData]);

    const location = useLocation();
    // Function to determine if the current route contains '/Home/AI'
    const isAIPage = location.pathname.includes('/Home/AI');


    const handleSortChange = (option) => {
        setSortOption(option);
        console.log(option)
        // Implement sorting logic here
        let sortedData = [...recentTransactionsData]; // Create a shallow copy of the data
        // Sort data based on the selected option
        switch (option) {
            case 'Ticker':
                sortedData.sort((a, b) => a.ticker.localeCompare(b.ticker)); // Sort by ticker name
                break;
            case 'Date':
                sortedData.sort((a, b) => {
                    const dateA = new Date(a.lastExecutionTime.split('-')[0]);
                    const dateB = new Date(b.lastExecutionTime.split('-')[0]);
                    return dateB - dateA; // Sort by date in descending order
                });
                break;
            case 'Type':
                sortedData.sort((a, b) => a.side.localeCompare(b.side)); // Sort by Type (Buy/Sell)
                break;
            case 'Order Type':
                sortedData.sort((a, b) => a.orderType.localeCompare(b.orderType)); // Sort by Order Type
                break;
            case 'Order Qty':
                sortedData.sort((a, b) => b.totalSize - a.totalSize); // Sort by Order Qty in descending order
                break;
            default:
                break;
        }
        // console.log(sortedData);
        
        setSortedTransactions(sortedData);
    };
    // Effect to initialize sorted transactions when the data is loaded or updated
    useEffect(() => {
        if (recentTransactionsData) {
            handleSortChange(sortOption); // Apply initial sort
        }
    }, [recentTransactionsData]);
    const getNumerator = (sizeAndFills) => {
        // Split the string at '/' and return the first part (numerator)
        return sizeAndFills.split('/')[0];
    };

    return (
        <Card className="main-card mt-3">
            <Card.Header className="pb-0">
                <div className="d-flex justify-content-between mt-1">
                    <div className="d-flex align-items-center">
                        <h2 className="medium-heading mb-0">Recent Transactions</h2>
                        {isAIPage ? (
                            <div className='ai_block'>
                                <span className="ml-2">AI</span>
                            </div>
                        ): (
                            <div></div>
                        )
                    } 
                    </div>
                    
                    <div>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            onClick={handleSvgClick}
                            style={{ cursor: 'pointer' }}
                            >
                            <path d="M12 13.5C11.7033 13.5 11.4133 13.412 11.1666 13.2472C10.92 13.0824 10.7277 12.8481 10.6142 12.574C10.5006 12.2999 10.4709 11.9983 10.5288 11.7074C10.5867 11.4164 10.7296 11.1491 10.9393 10.9393C11.1491 10.7296 11.4164 10.5867 11.7074 10.5288C11.9983 10.4709 12.2999 10.5006 12.574 10.6142C12.8481 10.7277 13.0824 10.92 13.2472 11.1666C13.412 11.4133 13.5 11.7033 13.5 12C13.5 12.3978 13.342 12.7794 13.0607 13.0607C12.7794 13.342 12.3978 13.5 12 13.5Z" fill="white" />
                            <path d="M18.75 13.5C18.4533 13.5 18.1633 13.412 17.9166 13.2472C17.67 13.0824 17.4777 12.8481 17.3642 12.574C17.2506 12.2999 17.2209 11.9983 17.2788 11.7074C17.3367 11.4164 17.4796 11.1491 17.6893 10.9393C17.8991 10.7296 18.1664 10.5867 18.4574 10.5288C18.7483 10.4709 19.0499 10.5006 19.324 10.6142C19.5981 10.7277 19.8324 10.92 19.9972 11.1666C20.162 11.4133 20.25 11.7033 20.25 12C20.25 12.3978 20.092 12.7794 19.8107 13.0607C19.5294 13.342 19.1478 13.5 18.75 13.5Z" fill="white" />
                            <path d="M5.25 13.5C4.95333 13.5 4.66332 13.412 4.41665 13.2472C4.16997 13.0824 3.97771 12.8481 3.86418 12.574C3.75065 12.2999 3.72094 11.9983 3.77882 11.7074C3.8367 11.4164 3.97956 11.1491 4.18934 10.9393C4.39912 10.7296 4.66639 10.5867 4.95737 10.5288C5.24834 10.4709 5.54994 10.5006 5.82403 10.6142C6.09812 10.7277 6.33238 10.92 6.49721 11.1666C6.66203 11.4133 6.75 11.7033 6.75 12C6.75 12.3978 6.59197 12.7794 6.31066 13.0607C6.02936 13.342 5.64783 13.5 5.25 13.5Z" fill="white" />
                        </svg>
                        <NavDropdown
                            show={showDropdown}
                            onToggle={handleToggle}
                            title=""
                            id="nav-dropdown"
                            align="end"
                            className="d-inline custom-nav-dropdown"
                            style={{ display: showDropdown ? 'block' : 'none' }}
                            >
                            <NavDropdown.Item className="dropdown-option" onClick={() => removeWidgetFromGrid('transaction')}>Close Window</NavDropdown.Item>
                        </NavDropdown>
                    </div>
                </div>
            </Card.Header>
            <Card.Body className="pt-0 pr-2 pl-2">
                <CustomDropdown
                    options={['Sort by Ticker', 'Sort by Date', 'Sort by Type', 'Sort by Order Type', 'Sort by Order Qty']}
                    selectedOption={sortOption}
                    onSelect={handleSortChange}
                />
                <div className="table-responsive scrollbar-custom m-h-222">
                    <Table hover className="transaction-table">
                        <thead>
                            <tr>
                                <th>Type</th>
                                <th>Date</th>
                                <th>Ticker</th>
                                <th>Order type</th>
                                <th>Order Qty</th>
                                <th>Executed Qty</th>
                                <th>Pending</th>
                                <th>Invalid</th>
                                <th>Limit Price</th>
                            </tr>
                        </thead>
                        <tbody>
                        {sortedTransactions && sortedTransactions.length > 0 ? (
                                sortedTransactions.map((transaction, index) => (
                                    <tr key={index}>
                                        <td>{transaction.side}</td>
                                        <td>{formatTradeTime(transaction.lastExecutionTime)}</td>
                                        <td>{transaction.ticker}</td>
                                        <td>{transaction.orderType}</td>
                                        <td>{transaction.totalSize}</td>
                                        <td>{transaction.filledQuantity}</td>
                                        <td>{transaction.remainingQuantity}</td>
                                        <td>{transaction.totalSize - transaction.filledQuantity}</td>
                                        <td>{transaction.avgPrice}</td>
                                    </tr>
                                    ))
                                ):(
                                    <tr>
                                        <td colSpan="9">No data available</td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>
                </div>
            </Card.Body>
        </Card>
    );
}

export default RecentTransaction;