import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import { TextField } from '@mui/material';
import axios from 'axios';

const UpdatePassword = ({ open, onClose, currentpassword }) => {
    const [setMessage] = useState('');
    const [passwordShown] = useState(false);
    const [confirmpasswordShown] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmpassword, setConfirmPassword] = useState('');
    const [passwordMismatchError, setPasswordMismatchError] = useState('');
    const [emptyPasswordError, setEmptyPasswordError] = useState('');
    const [emptyConfirmPasswordError, setEmptyConfirmPasswordError] = useState('');
    const [loading, setLoading] = useState(false);

    const handlePasswordSubmit = async (event) => {
        event.preventDefault();

        // Reset any previous error messages
        setPasswordMismatchError('');
        setEmptyPasswordError('');
        setEmptyConfirmPasswordError('');

        // Check for empty password
        if (!password) {
            setEmptyPasswordError('Password is required!');
        }

        // Check for empty confirm password
        if (!confirmpassword) {
            setEmptyConfirmPasswordError('Confirm password is required!');
        }

        // If either field is empty, exit early
        if (!password || !confirmpassword) {
            return;
        }

        // Check if the passwords match
        if (password !== confirmpassword) {
            setPasswordMismatchError('Passwords do not match!');
            return;
        }

        setLoading(true);

        try {
            const token = localStorage.getItem('accessToken');
            console.log(token);
            // Call the API to reset the password
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/reset-password`, {
                newPassword: password,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`, // Add the token to the Authorization header
                    }
                }
            );

            if (response.status === 200) {
                setMessage('Password reset successful!');
                onClose();
            }
        } catch (error) {
            // Handle errors (e.g., invalid credentials)
            if (error.response && error.response.data) {
                setMessage(error.response.data.message || 'An error occurred. Please try again.');
            } else {
                setMessage('An unexpected error occurred. Please try again later.');
            }
        } finally {
            setLoading(false); // Reset loading state after API call
        }

    };

    return(
        <Dialog open={open} onClose={onClose} aria-labelledby="settings-dialog-title" fullWidth PaperProps={{
            style: {
              width: '480px',
              maxWidth: '100%',
            },
          }} className="setting-dialog">
            <DialogTitle id="settings-dialog-title" className="d-flex justify-content-between mb-4">
                <div className="d-flex align-items-center">
                    <img style={{ float: 'none', height: "20px", width: "18px" }} src={`${process.env.PUBLIC_URL}/images/left.svg`} alt="left" className="close-icon" role="button" 
                    onClick={() => {
                        onClose();
                    }} />
                    <span style={{marginLeft:"10px"}}>Update Password</span>
                </div>
              <img style={{ float: 'none', height: "22px", width: "20px" }} src={`${process.env.PUBLIC_URL}/images/close.png`} alt="close" className="close-icon" role="button" onClick={onClose} />
            </DialogTitle>
            <DialogContent>
                <Form onSubmit={handlePasswordSubmit}>
                    <div className="mb-2">
                        <span style={{fontSize: "16px"}}>New Password</span>
                    </div>
                    <Form.Group controlId="formBasicEmail" className="control">
                        <FormControl className="square-form-control-update m-0" sx={{ m: 1, minWidth: 120 }}>
                            <TextField
                                placeholder=" "
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                fullWidth
                                margin="normal"
                                type={passwordShown ? 'text' : 'password'}
                            />
                        </FormControl>
                    </Form.Group>
                    {emptyPasswordError && (
                        <div className="text-danger mt-2">{emptyPasswordError}</div>
                    )}

                    <div className="mb-2 mt-3">
                        <span style={{fontSize: "16px"}}>Confirm New Password</span>
                    </div>
                    <Form.Group controlId="formBasicEmail" className="control">
                        <FormControl className="square-form-control-update m-0" sx={{ m: 1, minWidth: 120 }}>
                            <TextField
                                placeholder=" "
                                value={confirmpassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                fullWidth
                                margin="normal"
                                type={confirmpasswordShown ? 'text' : 'password'}
                            />
                        </FormControl>
                    </Form.Group>
                    {emptyConfirmPasswordError && (
                        <div className="text-danger mt-2">{emptyConfirmPasswordError}</div>
                    )}

                    {passwordMismatchError && (
                        <div className="text-danger mt-2">{passwordMismatchError}</div>
                    )}
                    
                    <div className="mt-2 mb-3 row justify-content-center">
                        <Button variant="primary" type="submit" style={{backgroundColor: '#317CFF', textTransform: 'none'}} className="mt-5 w-55 p-2 theme-btn-email" disabled={loading}>
                            {loading ? 'Submitting...' : 'Submit'}
                        </Button>
                    </div>
                </Form>
            </DialogContent>
        </Dialog>
    );
};

export default UpdatePassword;