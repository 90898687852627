import React, { useState, useEffect } from "react";
import { Card, Form, Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";

const ForgotPassword = () => {
    const location = useLocation();
    const initialEmail = location.state?.email || '';
    const [step, setStep] = useState(1);
    const [email, setEmail] = useState(initialEmail);
    const [code, setCode] = useState('');
    const [seconds, setSeconds] = useState(60);
    const [message, setMessage] = useState('');
    const [showPrimaryButton, setShowPrimaryButton] = useState(false);
    const [errors, setErrors] = useState({ email: '', api: '' });
    const [loading, setLoading] = useState(false);

    const sendResetCode = async () => {
        let valid = true;
        let newErrors = { email: '', api: '' }; // Reset errors

        if (!email) {
            newErrors.email = 'Email is required.';
            valid = false;
        }
        setErrors(newErrors);

        if (valid) {
            setLoading(true); // Set loading to true when API call starts
            try {
                // API call to send reset code
                await axios.post(`${process.env.REACT_APP_API_URL}/forgot-password`, { email });
                setStep(2);
                setSeconds(60);
                setShowPrimaryButton(false);
            } catch (error) {
                console.error("Failed to send reset code:", error);
                newErrors.api = 'Failed to send reset code. Please try again.';
                setErrors(newErrors);
            } finally {
                setLoading(false); // Reset loading state after API call
            }
        }
    };

    const handleEmailSubmit = async (event) => {
        event.preventDefault();
        await sendResetCode(); // Call the shared function
    };

    const handleCodeSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            const otp = code;
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/validate-otp-send-instructions`, { email, otp });
            setMessage('We have sent you the instructions. <br> Please check your email!');
            setStep(3);
        } catch (error) {
            console.error("Code verification failed:", error);
            setErrors(prevErrors => ({ ...prevErrors, api: 'Code verification failed. Please try again.' }));
            setCode('');
        } finally {
            setLoading(false);
        }
    };

    const handleResendCode = async () => {
        setSeconds(60);
        setShowPrimaryButton(false);
        await sendResetCode(); // Call the shared function
    };

    useEffect(() => {
        if (seconds > 0) {
            const timer = setTimeout(() => setSeconds(seconds - 1), 1000);
            return () => clearTimeout(timer);
        } else {
            setShowPrimaryButton(true);
        }
    }, [seconds]);

    return (
        <div className="login-container">
            <Card className="login-card">
                <Card.Body>
                    <div className="text-center mb-5 mt-2">
                        <img src={`${process.env.PUBLIC_URL}/images/logo.webp`} className="App-logo" alt="logo" />
                    </div>
                    {step === 1 ? (
                        <Form onSubmit={handleEmailSubmit}>
                            <p className="text-center gray-text" style={{ fontSize: "14px" }}>
                                Please enter your email address, and we will send you instructions to reset your password.
                            </p>
                            <Form.Group controlId="formBasicEmail" className="floating-label-group">
                                <Form.Control
                                    type="email"
                                    className="form-input"
                                    placeholder=" "
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <label className={email ? 'floating-label float' : 'floating-label'}>Email</label>
                            </Form.Group>
                            {errors.email && <div className="text-danger mt-1">{errors.email}</div>}
                            {errors.api && <div className="text-danger mt-1">{errors.api}</div>}
                            <div className="mt-5">
                                <Button
                                    variant="primary"
                                    type="submit"
                                    className="mt-5 w-100 p-2 theme-btn"
                                    disabled={loading}
                                >
                                    {loading ? "Sending..." : "Send code"}
                                </Button>
                                <div className="text-center mt-3">
                                    <Link to="/" state={{ email }} className="forgot-password">Back to log in</Link>
                                </div>
                            </div>
                        </Form>
                    ) : !message ? (
                        <Form onSubmit={handleCodeSubmit}>
                            <p className="text-center gray-text" style={{ fontSize: "14px" }}>
                                Please enter your code, and we will send you instructions to reset your password.
                            </p>
                            <Form.Group controlId="formBasicEmail" className="floating-label-group">
                                <Form.Control
                                    type="email"
                                    className="form-input"
                                    placeholder=" "
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    readOnly
                                />
                                <label className={email ? 'floating-label float' : 'floating-label'}>Email</label>
                            </Form.Group>
                            <Form.Group controlId="formBasicCode" className="floating-label-group mt-3">
                                <Form.Control
                                    type="text"
                                    className={`form-input ${errors.api ? 'is-invalid' : ''}`}
                                    value={code}
                                    onChange={(e) => {
                                        setCode(e.target.value);
                                        setErrors(prevErrors => ({ ...prevErrors, api: '' }));
                                    }}
                                />
                                <label className={code ? 'floating-label float' : 'floating-label'}>
                                    {errors.api ? 'please try again' : 'Code'}
                                </label>
                            </Form.Group>
                            <div>
                               
                                <Button
                                    variant="primary"
                                    type="submit"
                                    className="mt-5 w-100 p-2 theme-btn"
                                    disabled={loading}
                                >
                                    {loading ? "Submitting..." : "Submit"}
                                </Button>
                                {!showPrimaryButton ? (
                                    <Button variant="secondary" className="mt-3 w-100 p-2 theme-btn" disabled={seconds > 0}>
                                        Resend code ({seconds}s)
                                    </Button>
                                ) : (
                                    <Button variant="primary" type="button" onClick={handleResendCode} className="mt-3 w-100 p-2 theme-btn">
                                        Resend code
                                    </Button>
                                )}
                                <div className="text-center mt-3">
                                    <Link to="/" state={{ email }} className="forgot-password">Back to log in</Link>
                                </div>
                            </div>
                        </Form>
                    ) : (
                        <div className="text-center mt-3">
                            <p className="text-white large-text mb-0 inst-p" dangerouslySetInnerHTML={{ __html: message }}></p>
                            <Button type="button" className="m-3 w-100 theme-btn" style={{ backgroundColor: "transparent", border: "none" }}>
                            </Button>
                            <div className="text-center mt-0">
                                <Link to="/" state={{ email }} className="forgot-password">Back to log in</Link>
                            </div>
                        </div>
                    )}
                </Card.Body>
            </Card>
        </div>
    );
};

export default ForgotPassword;
