import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import { TextField } from '@mui/material';
import { Form } from 'react-bootstrap';
import { useDialogContext } from '../context/DialogContext';
import { useWidgetContext } from '../context/DragContext';

const CustomizeWidget = () => {
    const [activeMenu, setActiveMenu] = useState("option1");
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [searchQuery, setSearchQuery] = useState(''); // State for search input
    const { isCustomizeWidgetOpen, closeCustomizeWidget } = useDialogContext();
    const { addWidgetToGrid, setIsHighlighted, setAddedWidgetKey, grid, isWidgetAlreadyPlaced } = useWidgetContext();

    const widgetsByOption = {
        option1: [
            { src: 'commodity_chart.svg', label: 'Chart', key: 'chart', height: 3, width: 2 },
            { src: 'commodity_info.svg', label: 'Commodity Info', key: 'info', height: 1, width: 1 },
            { src: 'commodity_bid_ask.svg', label: 'Bid & Ask', key: 'bidask', height: 1, width: 1 },
            { src: 'commodity_supply_demand.svg', label: 'Supply & Demand', key: 'supplydemand', height: 1, width: 1 },
        ],
        option2: [
            { src: 'market_index_constituents.svg', label: 'Market Trends', key: 'trends', height: 1, width: 1 },
            { src: 'market_data.svg', label: 'Market Analysis', key: 'analysis', height: 1, width: 1 },
            { src: 'market_monitor.svg', label: 'Market Predictions', key: 'predictions', height: 1, width: 1 },
            { src: 'market_l.svg', label: 'Market G/L', key: 'gl', height: 1, width: 1 },
            { src: 'market_heat_map.svg', label: 'Heat Map', key: 'heatmap', height: 1, width: 1 },
            { src: 'market_index.svg', label: 'Market Index', key: 'index', height: 1, width: 1 },
            { src: 'market_top_sectors.svg', label: 'Top Sectors', key: 'topsectors', height: 1, width: 1 },
            { src: 'market_sector_list.svg', label: 'Sector List', key: 'sectorlist', height: 1, width: 1 },
        ],
        option3: [
            { src: 'general_watchlist.svg', label: 'Watchlist', key: 'watchlist', height: 1, width: 1 },
            { src: 'general_screener.svg', label: 'Screener', key: 'screener', height: 1, width: 1 },
            { src: 'general_news.svg', label: 'News', key: 'news', height: 1, width: 1 },
        ],
        option4: [
            { src: 'trade.svg', label: 'Trade', key: 'trade', height: 1, width: 1 },
            { src: 'trade_holdings.svg', label: 'Holdings', key: 'holdings', height: 1, width: 2 },
            { src: 'trade_history_order.svg', label: 'History Order', key: 'order', height: 1, width: 1 },
            { src: 'traderecent_transaction.svg', label: 'Recent Transactions', key: 'transaction', height: 1, width: 1 },
        ],
        option5: [
            { src: 'portfolio_account_overview.svg', label: 'Account Overview', key: 'accountoverview', height: 1, width: 1 },
            { src: 'portfolio_account_information.svg', label: 'Account Information', key: 'accountinformation', height: 2, width: 1 },
            { src: 'portfolio_performance.svg', label: 'Performance', key: 'performance', height: 2, width: 2 },
        ],
    };

    const allWidgets = Object.values(widgetsByOption).flat();

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value.toLowerCase()); // Update search query
    };

    const handleWidgetClick = (widgetKey) => {
        //console.log(widgetKey);
        let added = false;
        const newGrid = [...grid];
        if(isWidgetAlreadyPlaced(newGrid, widgetKey)) {
            alert("This widget is already placed.");
        } else {
            setIsHighlighted(true);
            setAddedWidgetKey(widgetKey)
            // addWidgetToGrid(widgetKey);
            closeCustomizeWidget(); // Close the dialog after adding the widget
            added = true;
        }
        
    };

    const renderContent = () => {
        const widgets = activeMenu === "all" ? allWidgets : widgetsByOption[activeMenu];
        const filteredWidgets = widgets.filter(widget =>
            widget.label.toLowerCase().includes(searchQuery) // Filter based on search query
        );

        return (
            <div className="content-area" style={{ display: 'flex', flexWrap: 'wrap' }}>
                {filteredWidgets.map((widget, index) => (
                    <div key={index} className="widget-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '15px', position: 'relative', width: '140px' }}
                        onMouseEnter={() => setHoveredIndex(index)}
                        onMouseLeave={() => setHoveredIndex(null)}
                    >
                        <img
                            style={{ width: "140px" }}
                            src={`${process.env.PUBLIC_URL}/images/customize_widgets/${widget.src}`}
                            alt={widget.label}
                            className="customize-icon"
                            role="button"
                            onClick={() => handleWidgetClick(widget.key)}
                        />
                        <img
                            style={{
                                width: "18px",
                                position: 'absolute',
                                top: '-7px',
                                right: '-7px',
                                opacity: hoveredIndex === index ? 1 : 0,
                                transition: 'opacity 0.3s ease-in-out',
                            }}
                            src={`${process.env.PUBLIC_URL}/images/green_plus.svg`}
                            alt="Add"
                            className="add-button"
                            role="button"
                        />
                        <div style={{ marginTop: '5px' }}>
                            {widget.label}
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <Dialog
        open={isCustomizeWidgetOpen} onClose={closeCustomizeWidget}
            aria-labelledby="settings-dialog-title"
            fullWidth
            PaperProps={{
                style: {
                    width: '750px',
                    maxWidth: '100%',
                },
            }}
            className="setting-dialog"
        >
            <DialogTitle id="settings-dialog-title" className="d-flex justify-content-between mb-2">
                Widgets
                <img
                    style={{ height: "22px", width: "20px", cursor: "pointer" }}
                    src={`${process.env.PUBLIC_URL}/images/close.png`}
                    alt="close"
                    onClick={closeCustomizeWidget}
                />
            </DialogTitle>
            <div className="dialog-body">
                <div className="sidebar">
                    <Form>
                        <FormControl className="square-form-control-search m-0" sx={{ m: 1, minWidth: 120 }} style={{ textAlign: 'left' }}>
                            <TextField
                                placeholder="Search Widgets"
                                value={searchQuery}
                                onChange={handleSearchChange} // Update search input
                                fullWidth
                                margin="normal"
                                type="text"
                            />
                        </FormControl>
                    </Form>
                    <div onClick={() => setActiveMenu("all")} className={`${activeMenu === "all" ? "active" : ""} mt-3`}>
                        <p className="mb-0"> All Widgets</p>
                    </div>
                    <div onClick={() => setActiveMenu("option1")} className={`${activeMenu === "option1" ? "active" : ""} mt-3`}>
                        <p className="mb-0"> Commodities</p>
                    </div>
                    <div onClick={() => setActiveMenu("option2")} className={`${activeMenu === "option2" ? "active" : ""} mt-3`}>
                        <p className="mb-0"> Market</p>
                    </div>
                    <div onClick={() => setActiveMenu("option3")} className={`${activeMenu === "option3" ? "active" : ""} mt-3`}>
                        <p className="mb-0"> General</p>
                    </div>
                    <div onClick={() => setActiveMenu("option4")} className={`${activeMenu === "option4" ? "active" : ""} mt-3`}>
                        <p className="mb-0"> Trade</p>
                    </div>
                    <div onClick={() => setActiveMenu("option5")} className={`${activeMenu === "option5" ? "active" : ""} mt-3`}>
                        <p className="mb-0"> Portfolio</p>
                    </div>
                </div>
                <div className="divider"></div>
                <div className="content-area scrollbar-custom">
                    {renderContent()}
                </div>
            </div>
        </Dialog>
    );
};

export default CustomizeWidget;
