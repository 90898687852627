import React, { useEffect, useState } from "react";
import Watchlist from "./components/Watchlist";
import NewsFeed from "./components/NewsFeed";
import Holdings from "./components/Holdings";
import RecentTransaction from "./components/RecentTransaction";
import AccountOverview from "./components/AccountOverview";
import AccountInformation from "./components/AccountInformation";
import Performance from "./components/Performance";
import { Container, Row, Col } from 'react-bootstrap';

const Portfolio = () => {
    const [trustDevice, setTrustDevice] = useState(false);
    useEffect(() => {
        const storedTrust = localStorage.getItem('trustDevice');
        if (storedTrust === 'true') {
            setTrustDevice(true);
        } else {
            setTrustDevice(false);
        }
    }, []);

    return (
        <>
            <Container fluid className="mb-3">
                <Row>
                    
                    <Col md={6} lg={3}>
                        <AccountOverview />
                        <AccountInformation />
                    </Col>
                    <Col md={6}>
                        <Holdings />
                        <Performance />
                    </Col>
                    <Col md={6} lg={3}>
                        <Watchlist />
                        <RecentTransaction />
                        <NewsFeed />
                        
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Portfolio;