import React, { useEffect, useRef, useState,useContext } from 'react';
import { createChart } from 'lightweight-charts';
import { Card, NavDropdown, ButtonGroup, Button, Spinner } from 'react-bootstrap';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import io from 'socket.io-client';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Popover } from '@mui/material';
import Switch from './Switch';
import { AuthContext } from '../context/AuthContext';
import { useWidgetContext } from '../context/DragContext';

const TradingChart = () => {

const containerRef = useRef(null);
    // console.log("Library Path:", `${process.env.PUBLIC_URL}/charting_library/`);


    useEffect(() => {
        if (!window.TradingView) {
            console.error('TradingView library not loaded!');
            return;
        }

        const widgetOptions = {
            container: containerRef.current,
            library_path: '/charting_library/',
            datafeed: new window.Datafeeds.UDFCompatibleDatafeed("https://demo-feed-data.tradingview.com"),
            symbol: 'AAPL', // Change to desired symbol
            interval: '1D',
            locale: 'en',
            fullscreen: true,
            autosize: true,
            theme: 'Dark',
            debug: true,
        };
        console.log(widgetOptions)
        const tvWidget = new window.TradingView.widget(widgetOptions);

        return () => {
            if (tvWidget) tvWidget.remove();
        };
    }, []);

    return <div id='trading-chart' className="main-card home-chart mt-3" ref={containerRef} style={{ width: '100%' }} />;
};

export default TradingChart;

