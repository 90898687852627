import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { useLocation } from "react-router-dom";
import FormControl from '@mui/material/FormControl';
import { TextField } from '@mui/material';
import axios from 'axios';

const UpdatePhoneNumber = ({ open, onClose, onUpdate, currentphone }) => {
    const location = useLocation();
    const initialPhone = location.state?.phone || currentphone || '';
    const [phone_number, setPhone] = useState(initialPhone);
    const [errors, setErrors] = useState({ phone: '' });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Update `name` state whenever `currentname` prop changes
        if (currentphone) {
            setPhone(currentphone);
        }
    }, [currentphone]);


    const handlePhoneSubmit = async (event) => {
        event.preventDefault();

        let newErrors = { ...errors };

        if (!phone_number) {
            console.log('no email');
            newErrors.phone_number = 'Phone Number is required.';
        } else {
            newErrors.phone_number = '';
        }

        setErrors(newErrors);

        // If there are no errors, close the dialog
        if (!newErrors.phone_number) {
            setLoading(true);

            try {
                const token = localStorage.getItem('accessToken'); // Get the token from localStorage
                const userId = localStorage.getItem('userId');
                const response = await axios.put(
                    `${process.env.REACT_APP_API_URL}/user/${userId}`, // Replace with your actual API endpoint
                    { phone_number },
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        }
                    }
                );

                console.log('Name updated successfully:', response.data);
                onClose(); // Close the dialog on success
                onUpdate("phone", phone_number); 
            } catch (error) {
                console.error("Error updating name:", error);
                // Handle error if needed
            } finally {
                setLoading(false);
            }
        }
    };

    return(
        <Dialog open={open} onClose={onClose} aria-labelledby="settings-dialog-title" fullWidth PaperProps={{
            style: {
              width: '480px',
              maxWidth: '100%',
            },
          }} className="setting-dialog">
            <DialogTitle id="settings-dialog-title" className="d-flex justify-content-between mb-4">
                <div className="d-flex align-items-center">
                    <img style={{ float: 'none', height: "20px", width: "18px" }} src={`${process.env.PUBLIC_URL}/images/left.svg`} alt="left" className="close-icon" role="button" 
                    onClick={() => {
                        onClose();
                    }} />
                    <span style={{marginLeft:"10px"}}>Update Phone Number</span>
                </div>
              <img style={{ float: 'none', height: "22px", width: "20px" }} src={`${process.env.PUBLIC_URL}/images/close.png`} alt="close" className="close-icon" role="button" onClick={onClose} />
            </DialogTitle>
            <DialogContent>
                <Form onSubmit={handlePhoneSubmit}>
                    <div className="mb-2">
                        <span style={{fontSize: "16px"}}>Phone Number</span>
                    </div>
                    <Form.Group controlId="formBasicEmail" className="control">
                        <FormControl className="square-form-control-update m-0" sx={{ m: 1, minWidth: 120 }}>
                            <TextField
                                placeholder=" "
                                value={phone_number}
                                onChange={(e) => setPhone(e.target.value)}
                                fullWidth
                                margin="normal"
                                type="text"
                            />
                        </FormControl>
                    </Form.Group>
                    {errors.phone && <div className="text-danger mt-1">{errors.phone}</div>}
                    
                    <div className="mt-2 mb-3 row justify-content-center">
                        <Button variant="primary" type="submit" style={{backgroundColor: '#317CFF', textTransform: 'none'}} className="mt-5 w-55 p-2 theme-btn-email" disabled={loading}>
                            {loading ? 'Submitting...' : 'Submit'}
                        </Button>
                    </div>
                </Form>
            </DialogContent>
        </Dialog>
    );
};

export default UpdatePhoneNumber;