import React, { useState, useEffect, useContext } from "react";
import { Container } from 'react-bootstrap';
import { useWidgetContext } from "./context/DragContext";
import CustomizeWidget from "./components/CustomizeWidget";
import Watchlist from "./components/Watchlist";
import NewsFeed from "./components/NewsFeed";
import TradingChart from "./components/TradingChart";
import BidAsk from "./components/BidAsk";
import Trade from "./components/Trade";
import SymbolDetails from "./components/SymbolDetails";
import AllocationPosition from "./components/AllocationPosition";
import Holdings from "./components/Holdings";
import AccountOverview from "./components/AccountOverview";
import AccountInformation from "./components/AccountInformation";
import Performance from "./components/Performance";
import RecentTransaction from "./components/RecentTransaction";
import Authorization from "./auth/Authorization";
import { AuthContext } from "./context/AuthContext";
import TradingViewAdvancedChart from "./components/TradingViewChart";

const Home = () => {
    const [trustDevice, setTrustDevice] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [highlightedSpaces, setHighlightedSpaces] = useState([]); // To track available empty spaces
    const [selectedWidget, setSelectedWidget] = useState(null); // Track selected widget for placement
    const { grid, setGrid, widgets, isHighlighted, setIsHighlighted, addedWidgetKey, testGird} = useWidgetContext(); // Assuming `setGrid` is provided in context
    const { openSettings, setOpenSettings, handleCloseSettings, account } = useContext(AuthContext);

    useEffect(() => {
        const storedTrust = localStorage.getItem('trustDevice');
        if (storedTrust === 'true') {
            setTrustDevice(true);
        } else {
            setTrustDevice(false);
        }

        const checkAccount = async () => {
            const response = await account();
            // console.log(response);
            
            if(response == "Make sure you authenticate first then visit this"){
                // setOpenSettings(true); 
            }
        }

        checkAccount();
    }, []);

    // Opens CustomizeWidget dialog
    const openCustomizeWidget = () => {
        setIsDialogOpen(true);
    };

    // Close the CustomizeWidget and highlight empty spaces
    const handleCloseCustomWidget = () => {
        setIsDialogOpen(false);
        highlightEmptySpaces();
    };

    // Find available empty spaces in the grid
    const highlightEmptySpaces = () => {
        const availableSpaces = [];
        grid.forEach((row, rowIndex) => {
            row.forEach((cell, colIndex) => {
                if (cell === 0 || cell === null) {
                    availableSpaces.push({ rowIndex, colIndex });
                }
            });
        });
        setHighlightedSpaces(availableSpaces); // Store available spaces
    };

    // Get the correct widget based on widget key
    const getWidget = (widgetKey) => {
        switch (widgetKey) {
            case 'chart': return <TradingChart />;
            case 'info': return <SymbolDetails />;
            case 'bidask': return <BidAsk />;
            case 'heatmap': return <AllocationPosition />;
            case 'watchlist': return <Watchlist isInPopup={true} />;
            case 'news': return <NewsFeed />;
            case 'holdings': return <Holdings />;
            case 'transaction': return <RecentTransaction />;
            case 'accountoverview': return <AccountOverview />;
            case 'accountinformation': return <AccountInformation />;
            case 'performance': return <Performance />;
            case 'trade': return <Trade />;
            default: return null;
        }
    };

    // Render all components based on the grid
    const getComponents = () => {
        const components = [];
        grid?.forEach((row, rowIndex) => {
            row?.forEach((cell, colIndex) => {
                if (cell !== 0) {
                    const widget = getWidget(cell);
                    components.push(
                        <div
                            key={`${rowIndex}-${colIndex}`}
                            style={{
                                gridRow: rowIndex + 1,
                                gridColumn: colIndex + 1,
                                gridColumnEnd: `span ${widgets[cell]?.width || 1}`,
                                gridRowEnd: `span ${widgets[cell]?.height || 1}`,
                                maxWidth: "100%",
                                overflow: "hidden"
                            }}
                        >
                            {widget}
                        </div>
                    );
                } else {
                    // const isHighlighted = highlightedSpaces.some(space => space.rowIndex === rowIndex && space.colIndex === colIndex);
                    components.push(
                        <div
                            key={`${rowIndex}-${colIndex}`}
                            style={{
                                gridRow: rowIndex + 1,
                                gridColumn: colIndex + 1,
                                border: isHighlighted ? '2px dotted #FFF' : 'none',
                                cursor: isHighlighted ? 'pointer' : 'default',
                                zIndex: isHighlighted ? '100' : '0',
                                borderRadius: '5px',
                                display: 'flex', // Apply flexbox
                                justifyContent: 'center', // Horizontally center content
                                alignItems: 'center', // Vertically center content
                                height: '100%', // Ensure the height takes up the full grid cell
                            }}
                            onClick={() => handleEmptySpaceClick(rowIndex, colIndex)} // Handle click on empty space
                        >
                            <div style={{display: isHighlighted ? 'block' : 'none', fontSize: "14px"}}>Drop Here</div>
                        </div>
                    );
                }
            });
        });
        // console.log(components)
        return components;
    };

    // Handle click on highlighted empty space
    const handleEmptySpaceClick = (rowIndex, colIndex) => {
        
        // Set the selected widget at the clicked empty space
        // const newGrid = [...grid];
        // newGrid[rowIndex][colIndex] = addedWidgetKey; // Place selected widget in the grid
        // setGrid(newGrid); // Update the grid state
        if(testGird(addedWidgetKey, rowIndex, colIndex)) {
            // Clear the highlighted spaces after placing the widget
            setHighlightedSpaces([]);
            setSelectedWidget(null); // Reset the selected widget
            setIsHighlighted(false)
        } 
        
    };

    // Select widget from CustomizeWidget dialog
    const selectWidget = (widgetKey) => {
        setSelectedWidget(widgetKey); // Set the widget selected for placement
        setIsDialogOpen(false); // Close the dialog after selection
    };

    return (
        <>
            <Container fluid>
                <div style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(4, 1fr)', // 4 columns
                    gridTemplateRows: 'repeat(3, 1fr)', // 3 rows
                    rowGap: '0px', // Vertical gap
                    columnGap: '14px',
                }}>
                    {getComponents()} {/* Render the grid components */}
                </div>
            </Container>

            {openSettings && (
                <Authorization open={openSettings} onClose={handleCloseSettings} />
            )}
        </>
    );
};

export default Home;
