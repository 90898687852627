import React, { useState } from "react";
import { Card, Form, Button } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom"; // Import useNavigate for redirection
import axios from 'axios'; // Import axios

const ResetPassword = () => {
    const location = useLocation();
    const navigate = useNavigate(); // Initialize navigate for redirection
    const getQueryParams = () => {
        const params = new URLSearchParams(location.search);
        return {
            token: params.get('token'),
        };
    };

    const { token } = getQueryParams();

    const [password, setPassword] = useState('');
    const [confirmpassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [passwordMismatchError, setPasswordMismatchError] = useState('');
    const [emptyPasswordError, setEmptyPasswordError] = useState('');
    const [emptyConfirmPasswordError, setEmptyConfirmPasswordError] = useState('');
    const [loading, setLoading] = useState(false); // Add loading state

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        // Reset any previous error messages
        setPasswordMismatchError('');
        setEmptyPasswordError('');
        setEmptyConfirmPasswordError('');
        setMessage(''); // Clear previous messages

        // Check for empty password
        if (!password) {
            setEmptyPasswordError('Password is required!');
            return;
        }

        // Check for empty confirm password
        if (!confirmpassword) {
            setEmptyConfirmPasswordError('Confirm password is required!');
            return;
        }

        // Check if the passwords match
        if (password !== confirmpassword) {
            setPasswordMismatchError('Passwords do not match!');
            return;
        }

        setLoading(true); // Set loading state to true before API call

        try {
            // Call the API to reset the password
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/reset-password`, {
                newPassword: password,
                token: token,
            });

            if (response.status === 200) {
                setMessage('Password reset successful! Redirecting to login...');
                setTimeout(() => {
                    navigate('/'); // Redirect to the login page after 2 seconds
                }, 2000);
            }
        } catch (error) {
            // Handle errors (e.g., invalid credentials)
            if (error.response && error.response.data) {
                setMessage(error.response.data.message || 'An error occurred. Please try again.');
            } else {
                setMessage('An unexpected error occurred. Please try again later.');
            }
        } finally {
            setLoading(false); // Reset loading state after API call
        }
    };

    return (
        <div className="login-container">
            <Card className="login-card">
                <Card.Body>
                    <div className="text-center mb-5 mt-2">
                        <img src={`${process.env.PUBLIC_URL}/images/logo.webp`} className="App-logo" alt="logo" />
                    </div>
                    {!message ? (
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="formBasicPassword" className="mt-3 floating-label-group">
                                <Form.Control
                                    type="password"
                                    className="form-input"
                                    placeholder=" "
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <label className={password ? 'floating-label float' : 'floating-label'}>New Password</label>
                            </Form.Group>
                            {emptyPasswordError && <div className="text-danger mt-2">{emptyPasswordError}</div>}

                            <Form.Group controlId="formBasicPasswordConfirm" className="mt-3 floating-label-group">
                                <Form.Control
                                    type="password"
                                    className="form-input"
                                    placeholder=" "
                                    value={confirmpassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                                <label className={confirmpassword ? 'floating-label float' : 'floating-label'}>Confirm New Password</label>
                            </Form.Group>
                            {emptyConfirmPasswordError && <div className="text-danger mt-2">{emptyConfirmPasswordError}</div>}
                            {passwordMismatchError && <div className="text-danger mt-2">{passwordMismatchError}</div>}

                            <div className="mt-2">
                                <Button variant="primary" type="submit" className="mt-5 w-100 p-2" disabled={loading}>
                                    {loading ? 'Submitting...' : 'Submit'}
                                </Button>
                                <div className="text-center mt-4">
                                    <Link to="/" className="forgot-password">Back to log in</Link>
                                </div>
                            </div>
                        </Form>
                    ) : (
                        <div className="text-center mt-3">
                            <p className="mb-5 mt-5 text-white large-text">{message}</p>
                            <div className="text-center mt-4">
                                <Link to="/" className="forgot-password">Back to log in</Link>
                            </div>
                        </div>
                    )}
                </Card.Body>
            </Card>
        </div>
    );
};

export default ResetPassword;
