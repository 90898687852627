import React, {useState, useContext, useEffect} from 'react';
import { Card, NavDropdown  } from 'react-bootstrap';
import Slider from '@mui/material/Slider';
import { useWidgetContext } from '../context/DragContext';
import { AuthContext } from '../context/AuthContext';

const marks = [
  {
    value: 410.11,
    label: '410.11',
  },
  {
    value: 425.13,
    label: '425.13',
  },
];

function valuetext(value) {
  return `${value}`;
}

function SymbolDetails() {

  const [showDropdown, setShowDropdown] = useState(false);
  const { removeWidgetFromGrid } = useWidgetContext();
  const { addWatchlist, watchlistData, deleteWatchlist, getWatchLists, symbolDetails } = useContext(AuthContext);
  const [isOnWatchlist, setIsOnWatchlist] = useState(false);
  const id = symbolDetails[0] ? symbolDetails[0]["conid"] : []; // Or any id you need
  const name = symbolDetails[0] ? symbolDetails[0]["55"] : ""; // Or any name you need
  const rows = symbolDetails[0] ? [{ C: symbolDetails[0]["conid"] }] : []; // Example rows data
  const [showMore, setShowMore] = useState(false);

  // console.log(symbolDetails);
  
  const handleToggle = (isOpen) => {
      setShowDropdown(isOpen);
  };

  const handleSvgClick = () => {
      setShowDropdown(!showDropdown);
  };

  useEffect(() => {
    // console.log('if');
    
    const isItemInWatchlist = watchlistData.some((list) => list.id == id);
    // console.log(isItemInWatchlist);
    
    setIsOnWatchlist(isItemInWatchlist);
  }, [watchlistData, id]);
  

  const handleAddClick = async () => {
    try {
      const response = await addWatchlist(id, name, rows); 
      // Assuming `addWatchlist` takes id, name, and rows
      // console.log(response);
      
      if(response.message == "Watchlist added successfully"){
        const watchlist = await getWatchLists(); 
        setIsOnWatchlist(true);
        console.log('Add Watchlist Response:', response);
      }
      else{
        console.log('failed to add to  watchlist');
      }
    } catch (error) {
      console.error('Error adding to watchlist:', error);
    }
  };

  const handleDeleteClick = async () => {
    try {
      const response = await deleteWatchlist(id); 
      // console.log(response)
      if(response.message == "Watchlist deleted successfully"){
        const watchlist = await getWatchLists(); 
        setIsOnWatchlist(false);
        console.log('Delete Watchlist Response:', response);
      }
      else{
        console.log('failed to add to  watchlist');
      }
    } catch (error) {
      console.error('Error adding to watchlist:', error);
    }
  };

  const toggleMoreInfo = () => {
    setShowMore(!showMore); // Toggle the visibility of the "More" and "Open Interest" divs
  };

  return (
    <Card className="main-card mt-3">
      <Card.Header className="pb-0">
        <div className="d-flex justify-content-between mt-1">
          <h2 className="medium-heading mb-0">{symbolDetails[0]?.["55"]}</h2>
            <div>
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  onClick={handleSvgClick}
                  style={{ cursor: 'pointer' }}
                  >
                  <path d="M12 13.5C11.7033 13.5 11.4133 13.412 11.1666 13.2472C10.92 13.0824 10.7277 12.8481 10.6142 12.574C10.5006 12.2999 10.4709 11.9983 10.5288 11.7074C10.5867 11.4164 10.7296 11.1491 10.9393 10.9393C11.1491 10.7296 11.4164 10.5867 11.7074 10.5288C11.9983 10.4709 12.2999 10.5006 12.574 10.6142C12.8481 10.7277 13.0824 10.92 13.2472 11.1666C13.412 11.4133 13.5 11.7033 13.5 12C13.5 12.3978 13.342 12.7794 13.0607 13.0607C12.7794 13.342 12.3978 13.5 12 13.5Z" fill="white" />
                  <path d="M18.75 13.5C18.4533 13.5 18.1633 13.412 17.9166 13.2472C17.67 13.0824 17.4777 12.8481 17.3642 12.574C17.2506 12.2999 17.2209 11.9983 17.2788 11.7074C17.3367 11.4164 17.4796 11.1491 17.6893 10.9393C17.8991 10.7296 18.1664 10.5867 18.4574 10.5288C18.7483 10.4709 19.0499 10.5006 19.324 10.6142C19.5981 10.7277 19.8324 10.92 19.9972 11.1666C20.162 11.4133 20.25 11.7033 20.25 12C20.25 12.3978 20.092 12.7794 19.8107 13.0607C19.5294 13.342 19.1478 13.5 18.75 13.5Z" fill="white" />
                  <path d="M5.25 13.5C4.95333 13.5 4.66332 13.412 4.41665 13.2472C4.16997 13.0824 3.97771 12.8481 3.86418 12.574C3.75065 12.2999 3.72094 11.9983 3.77882 11.7074C3.8367 11.4164 3.97956 11.1491 4.18934 10.9393C4.39912 10.7296 4.66639 10.5867 4.95737 10.5288C5.24834 10.4709 5.54994 10.5006 5.82403 10.6142C6.09812 10.7277 6.33238 10.92 6.49721 11.1666C6.66203 11.4133 6.75 11.7033 6.75 12C6.75 12.3978 6.59197 12.7794 6.31066 13.0607C6.02936 13.342 5.64783 13.5 5.25 13.5Z" fill="white" />
              </svg>
              <NavDropdown
                  show={showDropdown}
                  onToggle={handleToggle}
                  title=""
                  id="nav-dropdown"
                  align="end"
                  className="d-inline custom-nav-dropdown"
                  style={{ display: showDropdown ? 'block' : 'none' }}
                  >
                  <NavDropdown.Item className="dropdown-option" onClick={() => removeWidgetFromGrid('info')}>Close Window</NavDropdown.Item>
              </NavDropdown>
          </div>
        </div>
      </Card.Header>
      <Card.Body className="pt-0 scrollbar-custom">
        <div className="d-flex justify-content-between align-items-center mt-1">
          <div className="d-flex text-success align-items-center">
            <h1 className="large-h1 mb-0">413.00</h1>
            <p className="pl-2 mb-0 symbol-details-text"> 0.13 <br /> 0.04%</p>
          </div>
          <div>
            {isOnWatchlist ? (
              <img src={`${process.env.PUBLIC_URL}/images/filled_bookmark.svg`} height="23" width="20" onClick={handleDeleteClick}alt="bookmark-icon" />
            ) : (
              <img src={`${process.env.PUBLIC_URL}/images/bookmark.svg`} onClick={handleAddClick} height="28" width="24" alt="bookmark-icon" />
            )}
          </div>
        </div>

        <div className="row mt-2 pl-2 pr-2">
          <div className="col-6 text-danger bold">410.11</div>
          <div className="col-6 text-success text-end bold">425.13</div>
          <div className="col-2 low-high">Low</div>
          <div className="col-8 ps-0 pe-0">
            <Slider
              aria-label="Custom marks"
              defaultValue={413}
              min={410.11}
              max={425.13}
              step={0.01}
              getAriaValueText={valuetext}
              valueLabelDisplay="on"
              marks={marks}
              disabled 
            />
          </div>
          <div className="col-2 low-high text-end">High</div>
        </div>

        <div className="row pl-2 pr-1">
          <div className="col-3 mt-2">
            <span className="gray-text f-s-14">Prev. Close</span> <br />
            <span className="bold symbol-details-prev">{symbolDetails[0]?.["7741"] === "0" ? "0" : symbolDetails[0]?.["7741"] ? parseFloat(symbolDetails[0]["7741"]).toFixed(2) : "N/A"}</span>
          </div>
          <div className="col-3 mt-2">
            <span className="gray-text f-s-14">Ol</span> <br />
            <span className="bold symbol-details-prev">{symbolDetails[0]?.["7697"] === "0" ? "0" : symbolDetails[0]?.["7697"] ? parseFloat(symbolDetails[0]["7697"]).toFixed(2) : "N/A"}</span>
          </div>
          <div className="col-3 mt-2">
            <span className="gray-text f-s-14">Ol Chg</span> <br />
            <span className="bold symbol-details-prev">-201.00</span>
          </div>
          <div className="col-3 mt-2">
            <span className="gray-text f-s-14">Lot Size</span> <br />
            <span className="bold symbol-details-prev">{symbolDetails[0]?.["7059"] === "0" ? "0" : symbolDetails[0]?.["7059"] ? parseFloat(symbolDetails[0]["7059"]).toFixed(2) : "N/A"}</span>
          </div>
          <div className="col-3 mt-2">
            <span className="gray-text f-s-14">Volume</span> <br />
            <span className="bold symbol-details-prev">{symbolDetails[0]?.["87"] === "0" ? "0" : symbolDetails[0]?.["87"] ? parseFloat(symbolDetails[0]["87"]).toFixed(2) : "N/A"}</span>
          </div>
          <div className="col-3 mt-2">
            <span className="gray-text f-s-14">Bid</span> <br />
            <span className="bold symbol-details-prev">{symbolDetails[0]?.["84"] === "0" ? "0" : symbolDetails[0]?.["84"] ? parseFloat(symbolDetails[0]["84"]).toFixed(2) : "N/A"}</span>
          </div>
          <div className="col-3 mt-2">
            <span className="gray-text f-s-14">Ask</span> <br />
            <span className="bold symbol-details-prev">{symbolDetails[0]?.["86"] === "0" ? "0" : symbolDetails[0]?.["86"] ? parseFloat(symbolDetails[0]["86"]).toFixed(2) : "N/A"}</span>
          </div>
          {!showMore ? (
            <div className="col-3 mt-3" style={{cursor:'pointer'}} onClick={toggleMoreInfo}>
              <span className="symbol-details-prev">More</span>
              <img src={`${process.env.PUBLIC_URL}/images/down 2.svg`} alt="icon" style={{marginLeft:'4px', marginTop: '-3px'}}/>
            </div>
          ) : (
            <>
              <div className="col-3 mt-2">
                <span className="gray-text f-s-14">Open Interest</span> <br />
                <span className="bold symbol-details-prev">{symbolDetails[0]?.["7085"] === "0" ? "0" : symbolDetails[0]?.["7085"] ? parseFloat(symbolDetails[0]["7085"]).toFixed(2) : "N/A"}</span>
              </div>
              <div className="col-3 mt-2">
                <span className="gray-text f-s-14">5 Day</span> <br />
                <span className="bold symbol-details-prev">418.53</span>
              </div>
              <div className="col-3 mt-2">
                <span className="gray-text f-s-14">1 Month</span> <br />
                <span className="bold symbol-details-prev">418.53</span>
              </div>
              <div className="col-3 mt-2">
                <span className="gray-text f-s-14">3 Month</span> <br />
                <span className="bold symbol-details-prev">418.53</span>
              </div>
              <div className="col-3 mt-2">
                <span className="gray-text f-s-14">1 Year</span> <br />
                <span className="bold symbol-details-prev">418.53</span>
              </div>
              <div className="col-3 mt-2">
                <span className="gray-text f-s-14">Stochastic &K</span> <br />
                <span className="bold symbol-details-prev">{symbolDetails[0]?.["7696"] === "0" ? "0" : symbolDetails[0]?.["7696"] ? parseFloat(symbolDetails[0]["7696"]).toFixed(2) : "N/A"}</span>
              </div>
              <div className="col-3 mt-2">
                <span className="gray-text f-s-14">Weighted Alpha</span> <br />
                <span className="bold symbol-details-prev">{symbolDetails[0]?.["7718"] === "0" ? "0" : symbolDetails[0]?.["7718"] ? parseFloat(symbolDetails[0]["7718"]).toFixed(2) : "N/A"}</span>
              </div>
              <div className="col-3 mt-2">
                <span className="gray-text f-s-14">5-Day Chg</span> <br />
                <span className="bold symbol-details-prev">418.53</span>
              </div>
              <div className="col-3 mt-2">
                <span className="gray-text f-s-14">52-Week Range</span> <br />
                <span className="bold symbol-details-prev">
                  {symbolDetails[0]?.["7293"] || symbolDetails[0]?.["7294"]
                    ? `${symbolDetails[0]?.["7293"] === "0" ? "0" : symbolDetails[0]?.["7293"] ? parseFloat(symbolDetails[0]["7293"]).toFixed(2) : "-"} - ${
                        symbolDetails[0]?.["7294"] === "0" ? "0" : symbolDetails[0]?.["7294"] ? parseFloat(symbolDetails[0]["7294"]).toFixed(2) : "-"
                      }`
                    : "N/A"}
                </span>

              </div>
            </>
          )}
        </div>
      </Card.Body>
    </Card>
  );
}

export default SymbolDetails;
