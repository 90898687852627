// ProgressBar.js
import React from 'react';

const ProgressBar = ({ percentage, maxValue = 5.5  }) => {

  const value = (percentage / maxValue) * 100;
  return (
    <div className="progress-bar-wrapper">
     
      <div className="progress-container">
        <span className="bar-label">Safe</span>
        <div className="progress-bar-container">
          <div
            className={`filler ${percentage >= 2.88 ? 'red' : ''}`}
            style={{ width: `${value}%` }}
          >
            {/* Add the cross line */}
            <div className="cross-line"></div>
          </div>
        </div>
        <span className="bar-label right">Dangerous</span>
      </div>
    </div>
  );
};

export default ProgressBar;
