import React from 'react';

const InvestmentDot = ({ color }) => {
  const dotStyle = {
    backgroundColor: color,
    borderRadius: '50%',
    width: '6px',
    height: '6px',
    display: 'inline-block',
    marginRight: '6px',
    verticalAlign: 'middle',
  };

  return <div style={dotStyle}></div>;
};

export default InvestmentDot;
