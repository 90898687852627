import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../views/common/Header";
// import Home from "../views/Home";

const HomeLayout = () => {
    return (
        <div>
            <Header />
            <Outlet />
        </div>
    );
}

export default HomeLayout;
