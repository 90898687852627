import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const [auth, setAuth] = useState({
        accessToken: localStorage.getItem('accessToken') || null,
        refreshToken: localStorage.getItem('refreshToken') || null,
        userId: localStorage.getItem('userId') || null,
    });
    const [userData, setUserData] = useState(null);
    const [accountData, setAccountData] = useState(null);
    const [historicalData, setHistoricalData] = useState([]);
    const [selectedValue, setSelectedValue] = useState('ZC|CBOT|202412');
    const [symbolData, setSymbolData] = useState(null);
    const [openSettings, setOpenSettings] = useState(false);
    const [currentDate, setCurrentDate] = useState('');
    const [currentTime, setCurrentTime] = useState('');
    const [symbolTradeDate, setSymbolTradeDate] = useState(null);
    const handleOpenSettings = () => setOpenSettings(true);
    const handleCloseSettings = () => setOpenSettings(false);
    const [tradeData, setTradeData] = useState([]);
    const [recentTransactionsData, setRecentTransactionsData] = useState([]);
    const settingParams = {}; 

    const [accountInfoData, setaccountInfoData] = useState({
        
    });
    const [watchlistData, setWatchlistData] = useState([]);
    const [perfomanceChartData, setPerfomanceChartData] = useState([]);
    const [yourHoldingData, setYourHoldingData] = useState([]);
    const [preferenceData, setPreferenceData] = useState([]);
    const [aioperation, setAiOperation] = useState(1.25);
    const [symbolDetails, setSymbolDetails] = useState([]);
    const token = localStorage.getItem('accessToken');
    const userId = localStorage.getItem('userId');
    const [selectedNameVariable, setSelectedNameVariable] = useState(null);
    const [tradingHistoricalData, setTradingHistoricalData] = useState([]);
    const [availableBalance, setAvailableBalance] = useState();

    const login = (accessToken, refreshToken, userId) => {
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('userId', userId);
        setAuth({ accessToken, refreshToken, userId });
        navigate('/Home/Landing');
        // setOpenSettings(true); 
    };

    const logout = () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('userId');
        setAuth({ accessToken: null, refreshToken: null, userId:null });
        navigate('/');
    };

    useEffect(() => {
        // Get and format the current date
        const date = new Date();
        const formattedDate = date.toLocaleDateString(undefined, { year: 'numeric', month: 'short', day: 'numeric' });
        setCurrentDate(formattedDate);

        // Get and format the current time in 24-hour format based on user's timezone
        const formattedTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
        setCurrentTime(formattedTime);

        // account();
        getWatchLists();
        getOrderData();
        getRecentTransactions();
        getSymbolDetails();
        // accountsDetails();
        holdingsData();
        preformanceData();
        accountsSummary();
        allocations();
        // position();
        // trading_live_data();
        // getSymbolTradeDate();
    }, [])

    
    useEffect(() => {
        // Call the getSymbolDetails function whenever selectedValue changes
        getSymbolDetails();
    }, [selectedValue]);

    
    useEffect(() => {
        // console.log('called')
        // console.log(selectedNameVariable)
        if (selectedNameVariable) {
            getSymbolTradeDate();
        }
    }, [selectedNameVariable]);
    

    // Function to fetch user data
    const fetchUserData = async () => {
        if (userData) return userData; // Use existing data if already fetched
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/${auth.userId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.accessToken}`,
                },
            });
            setUserData(response.data);
            if (response.data.ai_settings && response.data.ai_settings.ai_operation) {
                setAiOperation(response.data.ai_settings.ai_operation);  // Set ai_operation from userData
            }
            return response.data;
        } catch (error) {
            console.error("Error fetching user data:", error);
            if (error.response?.status === 401) logout(); // Handle token expiration
        }
    };

    const account = async () => {
        try {
            const response =  await axios.get('https://ibkrapi.nodojicommodities.com/custom-apis/accounts-api')
            setAccountData(response.data);
            // console.log(response);
            return response.data;
        }
        catch(error){
            console.error("Error fetching user data:", error);
        }
    };

    const initEmailChange = async (newemail) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/init-email-change`, 
                { email: newemail },
                { headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.accessToken}`,
                }}
            );
            return response.data; // Return the response data on success
        } catch (error) {
            console.error("Error initiating email change:", error); // Updated log message
            if (error.response?.status === 401) {
                logout(); // Handle token expiration
            }
            return null; // Return null or an error object for consistency
        }
    };

    const changeEmail = async (otp,newemail) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/change-email`, 
                { otp: otp, newEmail: newemail, userId: auth.userId },
                { headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.accessToken}`,
                }}
            );
            return response.data; // Return the response data on success
        } catch (error) {
            console.error("Error initiating email change:", error); // Updated log message
            if (error.response?.status === 401) {
                // logout(); // Handle token expiration
            }
            return null; // Return null or an error object for consistency
        }
    };
    
    // useEffect(() => {
    //     console.log(selectedValue);
        
    //     const [symbol, exchange, expiry] = selectedValue.split('|');
    //     const fetchHistoricalData = async () => {
    //         try {
    //             const url = `http://44.192.89.34/historical_data?symbol=${symbol}&exchange=${exchange}&expiry=${expiry}`;
    //             const response = await axios.get(url);
    //             if (response.data && Array.isArray(response.data.historical_data)) {
    //                 const formattedData = response.data.historical_data.map(item => ({
    //                     date: new Date(item.date).getTime() / 1000, // Convert date string to Unix timestamp
    //                     open: item.open,
    //                     high: item.high,
    //                     low: item.low,
    //                     close: item.close,
    //                     volume: item.volume,
    //                 }));
    //                 setHistoricalData(formattedData); 
    //             } else {
    //                 console.error('Expected historical_data array, but got:', response.data);
    //                 return [];
    //             }
    //         } catch (error) {
    //             console.error('Error fetching historical data:', error);
    //             return [];
    //         }
    //     };
    //     fetchHistoricalData();
    // }, [selectedValue]);

    // useEffect(() => {
    //     const [symbol, exchange, expiry] = selectedValue.split('|');
    //     const fetchRealTimeData = async () => {
    //         const url = `http://44.192.89.34/realtime_data?symbol=${symbol}&exchange=${exchange}&expiry=${expiry}`;
    //         const response = await axios.get(url);
            
    //         if (response.data) {
    //             setSymbolData(response.data);
    //             const formattedData = {
    //                 date: new Date(response.data.date_time).getTime() / 1000, // Convert date string to Unix timestamp
    //                 open: response.data.open,
    //                 high: response.data.high,
    //                 low: response.data.low,
    //                 close: response.data.close,
    //                 volume: response.data.volume,
    //             };
    //         } else {
    //             console.error('Expected historical_data array, but got:', response.data);
    //             return [];
    //         }
    //     };
    //     fetchRealTimeData();
    // }, []);

    const getWatchLists = async () => {
        try {
            const response =  await axios.get('https://ibkrapi.nodojicommodities.com/custom-apis/getWatchLists')
            setWatchlistData(response.data);
            // console.log(response);
            // return response.data;
        }
        catch(error){
            console.error("Error fetching user data:", error);
            setOpenSettings(true); 
        }
    };

    const getOrderData = async () => {
        try {
            const response =  await axios.get('https://ibkrapi.nodojicommodities.com/custom-apis/orders')
            setRecentTransactionsData(response.data.orders);
            // console.log(response.data.orders);
            // return response.orders;
        }
        catch(error){
            console.error("Error fetching user data:", error);
            // setOpenSettings(true); 
        }
    };

    const getRecentTransactions = async () => {
        try {
            const response =  await axios.get('https://ibkrapi.nodojicommodities.com/custom-apis/trades')
            setTradeData(response.data);
            // console.log(response.data.orders);
            // return response.orders;
        }
        catch(error){
            console.error("Error fetching user data:", error);
            // setOpenSettings(true); 
        }
    };

    const createOrder = async (quantity, side, ordertype, cashqty) => {
        const [symbol] = selectedValue.split('|'); // Extract the symbol (like 'ZC', 'CL', etc.)
    
        // Determine the conid based on the symbol
        let conid;
        if (symbol === 'ZS') {
            conid = 11160664;
        } else if (symbol === 'CL') {
            conid = 17340715;
        } else if (symbol === 'ZC') {
            conid = 11160400;
        } else if (symbol === 'SI') {
            conid = 36557082;
        } else if (symbol === 'GC') {
            conid = 17340718;
        } else {
            console.error('Unknown symbol:', symbol);
            return; // Stop execution if symbol is unknown
        }
        try {
            const orderData = {
                orders: [
                    {
                        conid: conid,
                        orderType: ordertype,
                        side: side,
                        tif: "GTC",
                    }
                ]
            };
            if (quantity) {
                orderData.orders[0].quantity = quantity;
            }
            if (cashqty) {
                orderData.orders[0].cashqty = cashqty;
            }
            // console.log(orderData);
            const response = await axios.post(
                "https://ibkrapi.nodojicommodities.com/custom-apis/createOrder",
                orderData
            );
            return response.data;
        } catch (error) {
            console.error("Error in createOrder:", error);
        }
    };
    
    const addWatchlist = async (id, name, rows) => {
        try {
            const response = await axios.post(
                "https://ibkrapi.nodojicommodities.com/custom-apis/addWatchlist", 
                { id: id, name: name, rows: rows }
            );
            return response.data;
        } catch (error) {
            console.error("Error for addWatchlist:", error);
            
        }
    };

    const deleteWatchlist = async (id) => {
        try {
            const response = await axios.delete(
                `https://ibkrapi.nodojicommodities.com/custom-apis/deleteWatchlist?id=${id}`, 
            );
            return response.data;
        } catch (error) {
            console.error("Error for addWatchlist:", error);
            
        }
    };

    const aiSettings = async(ai_settings) => {
        try{
            console.log(ai_settings)
            const response = await axios.put(
                `${process.env.REACT_APP_API_URL}/user/${userId}`, // Replace with your actual API endpoint
                { ai_settings },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                }
            );
        }catch (error) {
            console.error("Error for addWatchlist:", error);
        }
    };

    const getSymbolTradeDate = async () => {
        // const [symbol, exchange, expiry] = selectedValue.split('|');
        let symbol;
        // console.log(selectedNameVariable);
        
        // if(selectedNameVariable == 'Corn'){
        //     symbol = 'ZC'
        // }else if(selectedNameVariable == 'Soybeans'){
        //     symbol = 'ZS'
        // }else if(selectedNameVariable == 'Gold'){
        //     symbol = 'GC'
        // }else if(selectedNameVariable == 'Crude Oil'){
        //     symbol = 'CL'
        // }
        
        try {
            const response =  await axios.get('https://ibkrapi.nodojicommodities.com/custom-apis/getSymbolDetails',
            {params: {
                symbol: selectedNameVariable,
                secType: "FUT"
                }
            });
            setSymbolTradeDate(response.data);
                // console.log(response.data);
                // return response.orders;
        }
        catch(error){
            console.error("Error fetching user data:", error);
            // setOpenSettings(true); 
        }
    };

    const fetchSymbolDetails = async (symbol) => {
        try {
            const response = await axios.get(
                'https://ibkrapi.nodojicommodities.com/custom-apis/getSymbolDetails',
                {
                    params: {
                        symbol: symbol,
                        secType: "FUT",
                    },
                }
            );
            return response.data; // Return the API response
        } catch (error) {
            console.error("Error fetching symbol details:", error);
            throw error; // Optionally rethrow the error to handle it in the caller function
        }
    };

    const getSymbolDetails = async () => {
        const [symbol, exchange, expiry] = selectedValue.split('|');
        let conid;
        if(symbol == 'ZS'){
            conid = 11160664
        }else if(symbol == 'CL'){
            conid = 17340715
        }else if(symbol == 'ZC'){
            conid = 11160400
        }else if(symbol == 'SI'){
            conid = 36557082
        }else if(symbol == 'GC'){
            conid = 17340718
        }

        try {
            const response =  await axios.get('https://ibkrapi.nodojicommodities.com/custom-apis/marketdata/snapshot',
            {params: {
                conids_str: conid,
                }
            });
            setSymbolDetails(response.data);
                // console.log(response.data);
                // return response.orders;
        }
        catch(error){
            console.error("Error fetching user data:", error);
            // setOpenSettings(true); 
        }
    };

    const accountsDetails = async () => {
        try {
            const response =  await axios.get('https://ibkrapi.nodojicommodities.com/custom-apis/accounts-details');
            // setaccountInfoData(response.data);
            // console.log(accountInfoData);
            // return response.orders;
        }
        catch(error){
            console.error("Error fetching user data:", error);
            // setOpenSettings(true); 
        }
    };

    const accountsSummary = async () => {
        try {
            const response =  await axios.get('https://ibkrapi.nodojicommodities.com/custom-apis/accountSummary');
            setaccountInfoData(response.data);
            console.log(response.data);
            // return response.orders;
        }
        catch(error){
            console.error("Error fetching user data:", error);
            // setOpenSettings(true); 
        }
    };
    const position = async () => {
        try {
            const response =  await axios.get('https://ibkrapi.nodojicommodities.com/custom-apis/portfolio-positions');
            // setaccountInfoData(response.data);
            console.log(response.data);
            // return response.orders;
        }
        catch(error){
            console.error("Error fetching user data:", error);
            // setOpenSettings(true); 
        }
    };

    const holdingsData = async () => {
        try {
            const response =  await axios.get('https://ibkrapi.nodojicommodities.com/custom-apis/holdings-new');
            setYourHoldingData(response.data.holdings);
            // console.log(response.data);
            // return response.orders;
        }
        catch(error){
            console.error("Error fetching user data:", error);
            // setOpenSettings(true); 
        }
    };

    // https://ibkrapi.nodojicommodities.com/custom-apis/account-performance-categorized
    const preformanceData = async (new_interval, startDate, endDate) => {
        try {
            const response =  await axios.post('https://ibkrapi.nodojicommodities.com/custom-apis/account-performance-categorized',
                {params: {
                    interval: new_interval,
                    start_date: startDate,
                    end_date: endDate
                }
            });
            setPerfomanceChartData(response.data)
            console.log("perfomanceChartData: "+ perfomanceChartData)
            // return response.orders;
        }
        catch(error){
            console.error("Error fetching user data:", error);
            // setOpenSettings(true); 
        }
    };

    const allocations = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/allocations`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.accessToken}`,
                },
            });

            // console.log(response.data)
            setPreferenceData(response.data);
            
            // return response.data;
        } catch (error) {
            console.error("Error fetching user data:", error);
            if (error.response?.status === 401) logout(); // Handle token expiration
        }
    };

    const editAllocations = async (name, date, position, amount, id) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/allocations/${id}`, 
                {name: name, date:date, position:position, amount:amount},
                {headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.accessToken}`,
                }}
            );
            console.log();
            
            if (response.status === 200) {
                console.log("Allocation updated successfully", response.data);
    
                // Call the get API to fetch the updated allocations
                await allocations(); // Assuming you have a function to get allocations
            }
            return response;
        } catch (error) {
            console.error("Error fetching user data:", error);
            if (error.response?.status === 401) logout(); // Handle token expiration
        }
    };

    const addAllocations = async (name, date, position, amount) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/allocations`, 
                {name: name, date:date, position:position, amount:amount},
                {headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.accessToken}`,
                }}
            );
            if (response.status === 200 || response.status === 201) {
                console.log("Allocation created successfully", response.data);
    
                // Call the get API to fetch the updated allocations
                await allocations(); // Assuming you have a function to get allocations
            }

            return response;
        } catch (error) {
            console.error("Error fetching user data:", error);
            if (error.response?.status === 401) logout(); // Handle token expiration
        }
    };

    const deleteAllocations = async (id) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_API_URL}/allocations/${id}`, 
                {headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.accessToken}`,
                }}
            );
            if (response.status === 200 || response.status === 201) {
                console.log("Allocation deleted successfully", response.data);
    
                // Call the get API to fetch the updated allocations
                await allocations(); // Assuming you have a function to get allocations
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
            if (error.response?.status === 401) logout(); // Handle token expiration
        }
    };

    const trading_historical_data = async (symbol) => {
        try {
            const response =  await axios.get('https://ibkrapi.nodojicommodities.com/custom-apis/get-historical-data',
                {params: {
                    // start_time_str : startDate,
                    // end_time_str : endDate,
                    schema : 'ohlcv-1s',
                    symbol : symbol
                }
            });
            setTradingHistoricalData(response.data)
            console.log("tradingHistoricalData: "+ tradingHistoricalData)
            // return response.orders;
        }
        catch(error){
            console.error("Error fetching trading_historical_data data:", error);
            // setOpenSettings(true); 
        }
    };

    const trading_live_data = async (symbol) => {
        try {
          const response = await axios.get('https://ibkrapi.nodojicommodities.com/custom-apis/get-live-data', {
            params: {
              schema: 'ohlcv-1s',
              symbol: symbol,
            },
          });
        //   console.log(response.data);
          
      
          if (response.data && response.data && response.data.live_data.length > 0) {
            return { data: response.data.live_data };
          } else {
            console.warn('No live data received for symbol:', symbol);
            return null;
          }
        } catch (error) {
          console.error("Error fetching live data:", error);
          return null;
        }
    };

    const supportEmail = async (title, description) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/support-email`, 
                { 
                    email: 'Taylor@NoDojiCommodities.com',
                    title: title,
                    description: description
                }
            );
            return response.data; // Return the response data on success
        } catch (error) {
            console.error("Error supportEmail:", error); // Updated log message
            return null; // Return null or an error object for consistency
        }
    };
      

    return (
        <AuthContext.Provider value={{ auth, login, logout, fetchUserData, userData, initEmailChange, changeEmail, historicalData, selectedValue, setSelectedValue, currentDate, currentTime, recentTransactionsData, accountInfoData, openSettings, handleOpenSettings, handleCloseSettings, setOpenSettings, watchlistData, yourHoldingData, aioperation, setAiOperation, account, createOrder, addWatchlist, deleteWatchlist, getWatchLists, aiSettings, preformanceData, tradeData, preferenceData, symbolDetails, editAllocations, addAllocations, settingParams, symbolTradeDate, setSelectedNameVariable, getSymbolTradeDate, fetchSymbolDetails, deleteAllocations, perfomanceChartData, trading_historical_data, trading_live_data, availableBalance, setAvailableBalance, supportEmail }}>
            {children}
        </AuthContext.Provider>
    );
};
