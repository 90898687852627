import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';

const Authorization = ({ open, onClose }) => {

    const handleAuthClick = async (event) => {
        window.open('https://ibkrapi.nodojicommodities.com', '_blank');
        // onClose();
    }

    const handleReloadClick = async (event) => {
        window.location.reload();
        // onClose();
    }
    return(
        <Dialog open={open} 
        onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
                onClose();
            }
        }}  
        aria-labelledby="settings-dialog-title" fullWidth PaperProps={{
            style: {
              width: '490px',
              maxWidth: '100%',
            },
          }} className="setting-dialog">
            <DialogTitle id="settings-dialog-title" className="d-flex justify-content-between mb-4">
                <div className="d-flex align-items-center">
                    <span style={{marginLeft:"10px"}}>Authentication</span>
                </div>
            </DialogTitle>
            <DialogContent>
                <span style={{fontSize: "16px"}}>Please authenticate by clicking on below authenticate button</span>
                <div className="mt-1 mb-3 row justify-content-center">
                    <Button variant="primary" type="submit" onClick={handleAuthClick} style={{backgroundColor: '#317CFF', textTransform: 'none'}} className="mt-3 w-55 p-2 theme-btn-email">
                        {'Authenticate'}
                    </Button>
                </div>
                <div  className='mt-5'>
                    <span style={{fontSize: "16px"}}>After authentication click on below button to reload the page</span>
                </div>
                <div className="mt-1 mb-3 row justify-content-center">
                    <Button variant="primary" type="submit" onClick={handleReloadClick} style={{backgroundColor: '#317CFF', textTransform: 'none'}} className="mt-3 w-55 p-2 theme-btn-email">
                        {'Reload'}
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default Authorization;