import { useContext } from 'react';
import { AuthContext } from './context/AuthContext';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
    const { auth } = useContext(AuthContext);

    if (!auth.accessToken) {
        // Redirect unauthenticated users to the login page
        return <Navigate to="/" />;
    }

    return children;
};

export default ProtectedRoute;
