import React, { useEffect, useState } from "react";
import RecentTransaction from "./components/RecentTransaction";
import AllocationPosition from "./components/AllocationPosition";
import Performance from "./components/Performance";
import PerformanceOverview from "./components/PerformanceOverview";
import SettingControls from "./components/SettingControls";
import { Container, Row, Col } from 'react-bootstrap';

const Portfolio = () => {
    const [trustDevice, setTrustDevice] = useState(false);
    useEffect(() => {
        const storedTrust = localStorage.getItem('trustDevice');
        if (storedTrust === 'true') {
            setTrustDevice(true);
        } else {
            setTrustDevice(false);
        }
    }, []);

    return (
        <>
            <Container fluid className="mb-3">
                <Row>
                    
                    <Col md={6} lg={3}>
                        <PerformanceOverview />
                        <AllocationPosition />
                    </Col>
                    <Col md={6}>
                        <Performance />
                        <RecentTransaction />
                    </Col>
                    <Col md={6} lg={3}>
                        <SettingControls />
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Portfolio;